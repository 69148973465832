<template>
  <header class="header">
    <div class="p-0">
      <div class="header-con d-flex justify-content-between align-items-center">
        <div class="header-left-side-con d-flex align-items-center">
          <div
            class="logo-before-design sidenavCollapse rotate down"
            @click="this.sideNavCollapse()"
          ></div>
          <div class="logo-con">
            <img :src="`${publicPath}assets/img/brand-logo.svg`" alt="" />
          </div>
        </div>
        <!-- <div class="switch" @click="clickSwitchBtn()" v-if="showModeButton">
          <div class="main main--light" id="main">
            <button type="button" id="switch">
              <i class="bx bxs-sun"></i>
              <span class="btn-switch"></span>
              <i class="bx bxs-moon"></i>
            </button>
          </div>
        </div> -->

        <!-- <div class="header-tab-con d-none d-xl-block"> -->
        <div class="header-tab-con">
          <!-- <div class="header-tab-con d-none"> -->
          <ul
            style="visibility: hidden"
            class="nav nav-pills flex-nowrap"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <router-link :to="{ name: 'home.all' }" class="nav-link active"
                >Exchangee</router-link
              >
            </li>
            <li class="nav-item" role="presentation">
              <!-- <button
                class="nav-link"
                id="pills-ezugi-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ezugi"
                type="button"
                role="tab"
                aria-controls="pills-ezugi"
                aria-selected="false"
              >
                Ezugi
              </button> -->
              <!-- <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                >Exchang</router-link
              > -->
            </li>
            <li class="nav-item" role="presentation">
              <!-- <button
                class="nav-link"
                id="pills-exchange-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ezugi"
                type="button"
                role="tab"
                aria-controls="pills-exchange"
                aria-selected="false"
              >
                Evolution
              </button> -->
              <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                >Evolution</router-link
              >
            </li>
            <li class="nav-item" role="presentation">
              <!-- <button
                class="nav-link"
                id="pills-exchange-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ezugi"
                type="button"
                role="tab"
                aria-controls="pills-exchange"
                aria-selected="false"
              >
                VIVO
              </button> -->
              <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                >VIVO</router-link
              >
            </li>
            <li class="nav-item" role="presentation">
              <!-- <button
                class="nav-link"
                id="pills-exchange-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ezugi"
                type="button"
                role="tab"
                aria-controls="pills-exchange"
                aria-selected="false"
              >
                Casinos
              </button> -->
              <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                >Casinos</router-link
              >
            </li>
          </ul>
        </div>
        <div
          class="header-right-slide-con d-flex align-items-center flex-nowrap"
        >
          <div
            class="header-btn-con align-items-center d-none d-sm-flex flex-nowrap"
          >
            <div class="bal-btn-item mr-2">
              <router-link :to="{ name: 'bets' }">
                <span class="bal-btn-item-text btn-default menu-toggle"
                  >Bets
                </span>
              </router-link>
            </div>

            <div
              class="bal-btn-item"
              v-if="this.showBetsButton"
              @mouseover="showDropdown"
              @mouseleave="hideDropdown"
            >
              <router-link :to="{ name: 'account-statement' }">
                <span class="bal-btn-item-text">Bal &colon;</span>
                <span class="bal-btn-item-value">{{ this.auth.balance }} </span>
              </router-link>

              <div
                v-if="isDropdownVisible"
                class="bg-white shadow p-3 rounded-lg"
                style="
                  position: absolute;
                  width: auto;
                  height: auto;
                  z-index: 5000;
                "
              >
                <table>
                  <tr>
                    <td>Chip</td>
                    <td>:</td>
                    <!-- <td style="text-align: right;">{{ (parseFloat(this.auth.balance) + (parseFloat(this.userLiability)*-1) - this.pl).toFixed(2)  }}</td> -->
                    <td style="text-align: right">
                      {{ this.chip.toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td>PL</td>
                    <td>:</td>
                    <td style="text-align: right">
                      {{ parseFloat(this.pl).toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>:</td>
                    <td style="text-align: right">
                      {{
                        (parseFloat(this.chip) + parseFloat(this.pl)).toFixed(2)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Exposure</td>
                    <td>:</td>
                    <td style="text-align: right">
                      {{ this.userLiability.toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Available Balance</td>
                    <td>:</td>
                    <td style="text-align: right">
                      {{
                        (
                          parseFloat(this.chip) +
                          parseFloat(this.pl) +
                          parseFloat(this.userLiability)
                        ).toFixed(2)
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="exp-btn-item">
              <router-link :to="{ name: 'liability' }">
                <span class="exp-btn-item-text">Exposure &colon;</span>
                <span class="exp-btn-item-value">{{ this.userLiability }}</span>
              </router-link>
            </div>
          </div>
          <div
            class="header-bal-btn-con d-sm-none d-flex align-items-center flex-nowrap"
          >
            <div class="header-bal-btn-con-item-1">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="">Bal</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <div class="bal-btn-item dropdown-item">
                    <a href="#">
                      <span class="bal-btn-item-text">Bal &colon;</span>
                      <span class="bal-btn-item-value">00</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="header-bal-btn-con-item-2">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="">Exp</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <div class="exp-btn-item dropdown-item">
                    <a href="#">
                      <span class="exp-btn-item-text">Exp &colon;</span>
                      <span class="exp-btn-item-value">00</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-dropdown-btn pe-2 pe-sm-3">
            <div class="">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="d-none d-sm-inline-block">{{
                  this.auth.display_name
                }}</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <!-- <a class="dropdown-item" href="#">My Account</a> -->
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'user-profile' }"
                    >My Account</router-link
                  >
                </li>
                <!-- <li><a class="dropdown-item" href="#">My Password</a></li> -->
                <li>
                  <router-link
                    :to="{ name: 'stack-button' }"
                    class="dropdown-item"
                    >Setting</router-link
                  >
                </li>
                <hr class="m-0" style="margin: 10px 0 !important" />
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="logOut"
                    >Log Out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="second-menu-mobile">
        <div
          class="responsive-header-second d-flex justify-content-start align-items-center overflow-auto p-3 p-xl-0 ps-0"
        >
          <!-- <div class="header-tab-con d-xl-none d-block"> -->
          <div class="header-tab-con">
            <ul class="nav nav-pills flex-nowrap" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <!-- <button
                  class="nav-link active"
                  id="pills-exchange-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-exchange"
                  type="button"
                  role="tab"
                  aria-controls="pills-exchange"
                  aria-selected="true"
                >
                  Exchange
                </button> -->
                <router-link
                  :to="{ name: 'home.all' }"
                  :class="`nav-link ${
                    $route.name == 'home.all' ? 'active' : null
                  }`"
                  >Exchange</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <router-link
                  :to="{ name: 'inplay' }"
                  :class="`nav-link ${
                    $route.name == 'inplay' ? 'active' : null
                  }`"
                  >In Play
                </router-link>
              </li>
              <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'liability' }" class="nav-link"
                  >My Market
                </router-link>
              </li>
              <li class="nav-item" role="presentation">
                <router-link
                  :to="{ name: 'cricket.all' }"
                  :class="`nav-link ${
                    $route.name == 'cricket.all' ? 'active' : null
                  }`"
                  >Cricket</router-link
                >
                <!-- <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Cricket</router-link
                > -->
              </li>
              <!-- <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Soccer</router-link
                >
              </li> -->
              <li class="nav-item" role="presentation">
                <router-link
                  :to="{ name: 'soccer.all' }"
                  :class="`nav-link ${
                    $route.name == 'soccer.all' ? 'active' : null
                  }`"
                  >Soccer</router-link
                >
              </li>
              <!-- <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Tennis</router-link
                >
              </li> -->
              <li class="nav-item" role="presentation">
                <router-link
                  :to="{ name: 'tennis.all' }"
                  :class="`nav-link ${
                    $route.name == 'tennis.all' ? 'active' : null
                  }`"
                  >Tennis</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Live Stream</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <!-- <button
                  class="nav-link"
                  id="pills-ezugi-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-ezugi"
                  type="button"
                  role="tab"
                  aria-controls="pills-ezugi"
                  aria-selected="false"
                >
                  Ezugi
                </button> -->
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Ezugi</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <!-- <button
                  class="nav-link"
                  id="pills-exchange-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-exchange"
                  type="button"
                  role="tab"
                  aria-controls="pills-exchange"
                  aria-selected="false"
                >
                  Evolution
                </button> -->
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Evolution</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <!-- <button
                  class="nav-link"
                  id="pills-exchange-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-exchange"
                  type="button"
                  role="tab"
                  aria-controls="pills-exchange"
                  aria-selected="false"
                >
                  VIVO
                </button> -->
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >VIVO</router-link
                >
              </li>
              <li class="nav-item" role="presentation">
                <!-- <button
                  class="nav-link"
                  id="pills-exchange-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-exchange"
                  type="button"
                  role="tab"
                  aria-controls="pills-exchange"
                  aria-selected="false"
                >
                  Casinos
                </button> -->
                <router-link :to="{ name: 'comingsoon' }" class="nav-link"
                  >Casinos</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import UserHome from './user/UserHome.vue'
// import AdminHome from './admin/AdminHome.vue'
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
import $ from "jquery";
export default {
  name: "HeaderAll",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      events: [],
      isDropdownVisible: false,
      userLiability: 0,
      pl: 0,
      chip: 0,
      total_loss: 0,
      total_win: 0,
      showModeButton: false,
      showBetsButton: true,
    };
  },
  // components: {
  // 	UserHome,AdminHome
  // },
  created: function () {
    this.auth = SessionService.getUser();
    this.updateUserBalance();
    this.initThemeMode();
    this.fetchPL();
    const currentRouteName = this.$route.name;
    const showButtonName = ["home.all"];
    if (showButtonName.includes(currentRouteName)) {
      this.showModeButton = true;
    } else {
      $("body").removeClass("darkmode");
      this.showModeButton = false;
    }
    // $(document).ready( function(){
    // $(".rotate").click(function () {
    //   $(this).toggleClass("down");
    // })
    // })
    this.getAuthUserDetails();
    // if(currentRouteName == 'event.detail'){
    //   this.showBetsButton = false
    // }else{
    this.showBetsButton = true;
    // }
  },
  methods: {
    openBetSideNav() {
      // e.preventDefault();
      $(".main-con").toggleClass("toggled");
    },
    showDropdown() {
      // alert(2)
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
    fetchPL() {
      DataService.getPLBalanace()
        .then((response) => {
          this.pl = response.data.data.pro_loss;
          this.total_win = response.data.data.total_win;
          this.total_loss = response.data.data.total_loss;
          this.chip = response.data.data.chip;
        })
        .catch((e) => {
          console.log("errorsssssss", e);
        });
    },
    getAuthUserDetails() {
      DataService.getAuthDetail()
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            if (data.status) {
              const auth = data.auth;
              this.auth.display_name = auth.display_name;
              this.auth.name = auth.name;
              this.auth.role = auth.role;
              this.auth.mobile_no = auth.mobile_no;
              this.auth.country = auth.country;
              this.auth.city = auth.city;
              // this.auth.balance      = auth.balance
              SessionService.setUser(this.auth);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    sideNavCollapse() {
      $(".rotate").toggleClass("down");
      $(".sidepanel").toggleClass("active");
    },
    clickSwitchBtn() {
      const mainContent = document.getElementById("main");
      if (mainContent.classList.contains("main--light")) {
        mainContent.classList.remove("main--light");
        mainContent.classList.add("main--dark");
        localStorage.setItem("mode", "dark");
        $("body").addClass("darkmode");
      } else {
        localStorage.setItem("mode", "light");
        $("body").removeClass("darkmode");
        mainContent.classList.remove("main--dark");
        mainContent.classList.add("main--light");
      }
    },
    initThemeMode() {
      const currentMode = localStorage.getItem("mode");
      // console.log('currentMode',currentMode)
      if (currentMode == "dark") {
        $("body").addClass("darkmode");
        setTimeout(() => {
          $(".main").removeClass("main--light");
          $(".main").addClass("main--dark");
        }, 120);
      } else {
        $("body").removeClass("darkmode");
      }
    },

    logOut() {
      DataService.logout()
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
        });
    },
    updateUserBalance() {
      DataService.getUserBalanace()
        .then((response) => {
          const findUser = SessionService.getUser();
          const balance = response.data.data.balance;
          if (response?.data?.data?.liabiliity) {
            this.userLiability = response.data.data.liabiliity;
          }
          findUser.balance = balance;
          this.auth = findUser;
          SessionService.setUser(findUser);
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>
