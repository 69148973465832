<template>
  <HeaderComponent :key="refreshHeaderKey" />
  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Login</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter your password"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Login</button>
        </div>
      </div>
    </div>
  </div>

  <div class="p-0 inner-events">
    <!-- container style="max-width: 1410px"  -->
    <div class="main-con d-flex">
      <SideBar />
      <SideBet @changeKeyHeader="updateHeader()" />
      <div class="tab-content w-100 overflow-auto" id="pills-tabContent">
        <div
          class="tab-pane fade show active w-100"
          id="pills-exchange"
          role="tabpanel"
          aria-labelledby="pills-exchange-tab"
          tabindex="0"
        >
          <main
            class="main-all-sec-con d-flex flex-column justify-content-start"
          >
            <!-- <div class="future-slider-sec">
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      :src="`${publicPath}assets/img/future-slider-sec-bg-1.png`"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      :src="`${publicPath}assets/img/future-slider-sec-bg-1.png`"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      :src="`${publicPath}assets/img/future-slider-sec-bg-1.png`"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <div
              class="match-score-sec"
              v-if="eventData[0]?.timeline?.matchType == 'TEST'"
            >
              <div class="match-score-sec-inner">
                <div
                  class="match-score-sec-vs-con d-flex justify-content-sm-between flex-column flex-sm-row"
                >
                  <div
                    class="match-score-sec-vs-con-left d-flex flex-column text-start"
                  >
                    <span class="team-name">{{
                      eventData[0]?.markets[0]?.runners[0]?.description
                        ?.runnerName
                    }}</span>
                    <span
                      class="score"
                      v-if="eventData[0]?.timeline?.score?.home?.inning1"
                      >{{
                        eventData[0]?.timeline?.score?.home?.inning1?.runs
                      }}-{{
                        eventData[0]?.timeline?.score?.home?.inning1
                          ?.wickets !== "ALL_OUT"
                          ? eventData[0]?.timeline?.score?.home?.inning1
                              ?.wickets
                          : "10"
                      }}
                      {{ eventData[0]?.timeline?.score?.home?.inning1?.overs }}
                      OV</span
                    >
                    <span class="score" v-else>0-0 0.0 OV</span>
                    <span
                      class="score"
                      v-if="eventData[0]?.timeline?.score?.home?.inning2"
                      >{{
                        eventData[0]?.timeline?.score?.home?.inning2?.runs
                      }}-{{
                        eventData[0]?.timeline?.score?.home?.inning2
                          ?.wickets !== "ALL_OUT"
                          ? eventData[0]?.timeline?.score?.home?.inning2
                              ?.wickets
                          : "10"
                      }}
                      {{ eventData[0]?.timeline?.score?.home?.inning2?.overs }}
                      OV</span
                    >
                    <span class="score" v-else>0-0 0.0 OV</span>
                  </div>
                  <img
                    class="vs-center align-self-center"
                    :src="`${publicPath}assets/img/vs-only-new.svg`"
                    alt=""
                  />
                  <div
                    class="match-score-sec-vs-con-right d-flex flex-column text-end"
                  >
                    <span class="team-name">{{
                      eventData[0]?.markets[0]?.runners[1]?.description
                        ?.runnerName
                    }}</span>
                    <span
                      class="score"
                      v-if="eventData[0]?.timeline?.score?.away?.inning1"
                      >{{
                        eventData[0]?.timeline?.score?.away?.inning1?.runs
                      }}-{{
                        eventData[0]?.timeline?.score?.away?.inning1
                          ?.wickets !== "ALL_OUT"
                          ? eventData[0]?.timeline?.score?.away?.inning1
                              ?.wickets
                          : "10"
                      }}
                      {{ eventData[0]?.timeline?.score?.away?.inning1?.overs }}
                      OV</span
                    >
                    <span class="score" v-else>0-0 0.0 OV</span>
                    <span
                      class="score"
                      v-if="eventData[0]?.timeline?.score?.home?.inning2"
                      >{{
                        eventData[0]?.timeline?.score?.away?.inning2?.runs
                      }}-{{
                        eventData[0]?.timeline?.score?.away?.inning2
                          ?.wickets !== "ALL_OUT"
                          ? eventData[0]?.timeline?.score?.away?.inning2
                              ?.wickets
                          : "10"
                      }}
                      {{ eventData[0]?.timeline?.score?.away?.inning2?.overs }}
                      OV</span
                    >
                    <span class="score" v-else>0-0 0.0 OV</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="match-score-sec" v-else>
              <div class="match-score-sec-inner">
                <div class="match-score-sec-vs-con">
                  <div
                    class="d-flex justify-content-sm-between flex-column flex-sm-row"
                  >
                    <div
                      class="match-score-sec-vs-con-left d-flex flex-column text-start"
                    >
                      <span class="team-name">{{
                        eventData[0]?.markets[0]?.runners[0]?.description
                          ?.runnerName ? eventData[0]?.markets[0]?.runners[0]?.description
                          ?.runnerName : this.team1_name 
                      }}</span>
                      <span
                        class="score"
                        v-if="eventData[0]?.timeline?.score?.home?.inning1"
                        >{{
                          eventData[0]?.timeline?.score?.home?.inning1?.runs
                        }}-{{
                          eventData[0]?.timeline?.score?.home?.inning1
                            ?.wickets !== "ALL_OUT"
                            ? eventData[0]?.timeline?.score?.home?.inning1
                                ?.wickets
                            : "10"
                        }}
                        {{
                          eventData[0]?.timeline?.score?.home?.inning1?.overs
                        }}
                        OV</span
                      >
                      <span
                        class="score"
                        v-else-if="eventData[0]?.timeline?.score?.home?.inning2"
                        >{{
                          eventData[0]?.timeline?.score?.home?.inning2?.runs
                        }}-{{
                          eventData[0]?.timeline?.score?.home?.inning2
                            ?.wickets !== "ALL_OUT"
                            ? eventData[0]?.timeline?.score?.home?.inning2
                                ?.wickets
                            : "10"
                        }}
                        {{
                          eventData[0]?.timeline?.score?.home?.inning2?.overs
                        }}
                        OV</span
                      >
                      <span class="score" v-else>0-0 0.0 OV</span>
                    </div>
                    <div
                      class="match-score-sec-vs-con-left d-flex flex-column text-center"
                    >
                      <!-- <img
                        class="vs-center align-self-center"
                        :src="`${publicPath}assets/img/vs-only-new.svg`"
                        alt=""
                      /> -->
                      <span class="team-name remove-capitalize">V/s</span>
                      <div class="score" v-if="eventData[0]?.event?.openDate">
                        {{ this.event_type_data }} Match Start :
                        {{
                          eventData[0]?.event?.openDate
                            ? new Date(
                                eventData[0]?.event?.openDate
                              ).toLocaleString("en-IN", { timeZone: "Asia/Dubai" }).toUpperCase()
                            : ""
                        }}
                      </div>
                      <div class="score" v-else>
                        {{ this.event_type_data }} Match Start :
                        {{ new Date(
                                this.match_time
                              ).toLocaleString("en-IN", { timeZone: "Asia/Dubai" }).toUpperCase() }}
                      </div>
                    </div>
                    <div
                      class="match-score-sec-vs-con-right d-flex flex-column text-end"
                    >
                      <span class="team-name">{{
                        eventData[0]?.markets[0]?.runners[1]?.description
                          ?.runnerName ? eventData[0]?.markets[0]?.runners[1]?.description
                          ?.runnerName : this.team2_name
                      }}</span>
                      <span
                        class="score"
                        v-if="eventData[0]?.timeline?.score?.away?.inning1"
                        >{{
                          eventData[0]?.timeline?.score?.away?.inning1?.runs
                        }}-{{
                          eventData[0]?.timeline?.score?.away?.inning1
                            ?.wickets !== "ALL_OUT"
                            ? eventData[0]?.timeline?.score?.away?.inning1
                                ?.wickets
                            : "10"
                        }}
                        {{
                          eventData[0]?.timeline?.score?.away?.inning1?.overs
                        }}
                        OV</span
                      >
                      <span
                        class="score"
                        v-else-if="eventData[0]?.timeline?.score?.home?.inning2"
                        >{{
                          eventData[0]?.timeline?.score?.away?.inning2?.runs
                        }}-{{
                          eventData[0]?.timeline?.score?.away?.inning2
                            ?.wickets !== "ALL_OUT"
                            ? eventData[0]?.timeline?.score?.away?.inning2
                                ?.wickets
                            : "10"
                        }}
                        {{
                          eventData[0]?.timeline?.score?.away?.inning2?.overs
                        }}
                        OV</span
                      >
                      <span class="score" v-else>0-0 0.0 OV</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="gap: 0px 0">
              <div class="tab-content-main">
                <div
                  :class="`left ${this.eventSideFull ? 'left-side-open' : ''}`"
                >
                  <button
                    type="button"
                    class="btn btn-collpase sidebarCollapse"
                    v-if="eventData[0]"
                    @click="this.eventListBar()"
                  ></button>
                  <div class="p-2">
                    <div class="d-flex flex-column">
                      <div
                        class="tab-2-content-sec-1"
                        v-for="(event, index) in eventData"
                        v-bind:index="index"
                        v-bind:key="event.id"
                      >
                        <div
                          class="container p-0"
                          v-for="(market, indexM) in event.markets"
                          v-bind:index="indexM"
                          v-bind:key="market.id"
                        >
                          <div
                            class="tab-content"
                            id="pills-tabContent"
                            v-if="
                              market?.marketDefinition?.status !== 'CLOSED' &&
                              (userSelectedMarket === null ||
                                indexM === userSelectedMarket)
                            "
                          >
                            <div
                              class="tab-pane fade show active"
                              id="pills-in-play"
                              role="tabpanel"
                              aria-labelledby="pills-in-play-tab"
                              tabindex="0"
                            >
                              <div class="tab-2-content-sec-1-inner-con">
                                <div
                                  class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                                >
                                  <h3 class="tab-2-content-sec-1-head linemarket-heading">
                                    <!-- <span class="pr-2">
                                      <img
                                        :src="`${publicPath}assets/img/match-d.svg`"
                                        alt=""
                                      />
                                    </span> -->
                                    {{ market.description.marketName }}
                                  </h3>

                                  <div class="text-btn-main-head-con">
                                    <div class="text-head-btn d-flex">
                                      <div class="text-btn-head">
                                        {{
                                          market.description.bettingType ==
                                          "ODDS"
                                            ? "Back"
                                            : "No"
                                        }}
                                      </div>
                                      <div class="text-btn-head">
                                        {{
                                          market.description.bettingType ==
                                          "ODDS"
                                            ? "Lay"
                                            : "Yes"
                                        }}
                                      </div>
                                    </div>
                                    <div
                                      class="text-btn-head rule-btn linemarket-heading"
                                      @click="
                                        this.showMarketRule(
                                          market.description.rules
                                        )
                                      "
                                    >
                                      <!-- <span class="pr-2">
                                        <img
                                          :src="`${publicPath}assets/img/rules-new-d.svg`"
                                          alt=""
                                        />
                                      </span> -->
                                      Rules 
                                    </div>
                                  </div>

                                  <!-- <div class="text-btn-main-head-con d-flex justify-content-between align-items-center"
                                  >
                                    <div class="text-btn-head">Back</div> -->
                                  <!-- <div class="text-btn-head">x</div> -->
                                  <!-- <div class="text-btn-head">Lay</div>
                                    <div class="text-btn-head rule-btn"
                                        @click="this.showMarketRule()">
                                        <span class="pr-2">
                                          <img
                                            :src="`${publicPath}assets/img/rules-new-d.svg`"
                                            alt=""
                                          />
                                        </span>
                                        Rules
                                    </div> -->
                                  <!-- </div> -->
                                </div>
                                <div
                                  class="item-con d-flex justify-content-between align-items-center flex-wrap"
                                  v-for="(runners, indexR) in market.runners"
                                  v-bind:index="indexR"
                                  v-bind:key="runners.id"
                                  style="padding: 1rem"
                                >
                                
                                  <div
                                    class="item-con-inner-con-1 d-flex align-items-center justify-content-between TEST001"
                                  >
                                    <span class="pr-2">
                                        <img
                                          :src="`${publicPath}assets/img/match-d.svg`"
                                          alt=""
                                        />
                                      </span>
                                    <span class="text-2">{{
                                      runners?.description?.runnerName
                                    }}</span>
                                  </div>
                                  <div
                                    class="item-con-inner-con-2 vif d-flex flex-nowrap align-items-center justify-content-start"
                                    v-if="
                                      market.description.bettingType == 'ODDS'
                                    "
                                  >
                                    <div
                                      class="text-4"
                                      v-if="
                                        this.eventLiabiliity[
                                          market.description.marketName
                                        ] &&
                                        runners?.description?.runnerName !=
                                          'Tie'
                                      "
                                    >
                                      <span
                                        v-if="
                                          this.eventLiabiliity?.[
                                            market.description.marketName
                                          ]?.[
                                            runners?.description?.runnerName
                                          ] &&
                                          this.isNumberOrString(
                                            market.description.marketName,
                                            runners?.description?.runnerName
                                          )
                                        "
                                        :class="`${
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][runners?.description?.runnerName] >=
                                          0
                                            ? 'text-success'
                                            : 'text-danger'
                                        }`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][runners?.description?.runnerName] !=
                                          "0.00"
                                            ? this.eventLiabiliity[
                                                market.description.marketName
                                              ][
                                                runners?.description?.runnerName
                                              ]
                                            : ""
                                        }}
                                      </span>
                                      <span
                                        v-else
                                        :class="`${
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ]['away'] >= 0
                                            ? 'text-success'
                                            : 'text-danger'
                                        }`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ]["away"] != "0.00"
                                            ? this.eventLiabiliity[
                                                market.description.marketName
                                              ]["away"]
                                            : ""
                                        }}
                                      </span>
                                    </div>

                                    <div
                                      class="text-4 fancy-bet"
                                      v-else-if="
                                        this.eventLiabiliity[
                                          market.description.marketName
                                        ]
                                      "
                                    >
                                      <!-- {{ this.eventLiabiliity }} -->

                                      <!-- {{  this.getFancyLaibility(market.description.marketName , runners?.description?.runnerName) }} -->
                                      <span
                                        v-if="
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[0]
                                        "
                                        :class="`text-danger`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[0]
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[1]
                                        "
                                        :class="`text-success`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[1]
                                        }}
                                      </span>
                                    </div>
                                    <!-- <div class="text-4" v-if="market.description.marketType !== 'MATCH_ODDS' && 
                                      market.description.marketType !==
                                        'COMPLETED_MATCH' && 
                                      market.description.marketType !==
                                        'MATCH_ODDS_LO_TIE' &&
                                      market.description.marketType !== 'TIED_MATCH' &&
                                      market.description.marketType !== 'TOTAL_SIXES' && 
                                      market.description.marketType !== 'TO_WIN_THE_TOSS' && 
                                      market.description.marketType !== 'TOTAL_FOURS' && 
                                      market.description.marketType !== 'SUPER_OVER' && 
                                      market.description.marketType !== 'TEAMA_1ST_INN_RUNS' && 
                                      market.description.marketType !== 'TEAMA_2ND_INN_RUNS' && 
                                      market.description.marketType !== 'TEAMB_1ST_INN_RUNS' && 
                                      market.description.marketType !== 'TEAMB_2ND_INN_RUNS'  
                                      "> -->
                                    <div
                                      class="text-4"
                                      v-if="
                                        market.description.bettingType !==
                                        'ODDS'
                                      "
                                    >
                                      <button
                                        class="hidden-sm hidden-xs btn btn-danger btn-sm"
                                        @click="
                                          this.openBookEventModel(
                                            market.marketId,
                                            market.description.marketName,
                                            runners?.description?.runnerName
                                          )
                                        "
                                      >
                                        Book
                                      </button>
                                    </div>
                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[2]
                                          ?.price
                                          ? // ? '1'
                                            '3'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[2]?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 2,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[2]?.price
                                              ? // ? '1'
                                                '3'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[2]?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item"
                                      >
                                      </span>

                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[2]?.price
                                            ? runners?.exchange
                                                ?.availableToBack?.[2]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[1]
                                          ?.price
                                          ? // ? '1'
                                            '3'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[1]?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 1,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[1]?.price
                                              ? // ? '1'
                                                '3'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[1]?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom second-item"
                                      >
                                      </span>
                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[1]?.price
                                            ? runners?.exchange
                                                ?.availableToBack?.[1]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[0]
                                          ?.price
                                          ? '1'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[0]?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 0,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[0]?.price
                                              ? '1'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[0]?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom second-item"
                                      >
                                      </span>
                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[0]?.price
                                            ? runners?.exchange
                                                ?.availableToBack?.[0]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>
                                    <!-- <span class="voice-icon voice">
                                      <a href="javascript:void(0)"><img :src="`${publicPath}assets/img/voice.svg`" alt="" /></a>
                                    </span> -->
                                    <span
                                      :class="`text-btn  text-btn-${
                                        runners?.exchange?.availableToLay?.[0]
                                          ?.price
                                          ? '6'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[0]
                                            ?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 0,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[0]?.price
                                              ? '6'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item"
                                        >{{
                                          runners?.exchange?.availableToLay?.[0]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom second-item"
                                      >
                                      </span>
                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange?.availableToLay?.[0]
                                            ?.price
                                            ? runners?.exchange
                                                ?.availableToLay?.[0]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToLay?.[1]
                                          ?.price
                                          ? // ? '6'
                                            '3'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[1]
                                            ?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 1,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[1]?.price
                                              ? // ? '6'
                                                '3'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item">
                                        {{
                                          runners?.exchange?.availableToLay?.[1]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom second-item"
                                      >
                                      </span>
                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange?.availableToLay?.[1]
                                            ?.price
                                            ? runners?.exchange
                                                ?.availableToLay?.[1]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToLay?.[2]
                                          ?.price
                                          ? // ? '6'
                                            '3'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[2]
                                            ?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 2,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[2]?.price
                                              ? // ? '6'
                                                '3'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom first-item suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom first-item">
                                        {{
                                          runners?.exchange?.availableToLay?.[2]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom second-item"
                                      >
                                      </span>
                                      <span v-else class="zoom second-item">
                                        {{
                                          runners?.exchange?.availableToLay?.[2]
                                            ?.price
                                            ? runners?.exchange
                                                ?.availableToLay?.[2]?.size
                                            : " "
                                        }}
                                      </span>
                                    </span>
                                    <!-- <span class="text-btn text-btn-6" v-for=" (back, index) in runners.exchange?.availableToBack" v-bind:index="index" v-bind:key="back.id">{{ back.price }}</span> -->

                                    <!-- <span class="text-btn text-btn-4">-</span>
                                      <span class="text-btn text-btn-5">2.11</span>
                                      <span class="text-btn text-btn-6">0.6</span> -->
                                  </div>
                                  <div
                                    class="item-con-inner-con-2 velse d-flex flex-nowrap align-items-center justify-content-start"
                                    v-else
                                  >
                                    <!-- <span class="text-btn text-btn-1" v-for=" (lay, index) in runners.exchange?.availableToLay" v-bind:index="index" v-bind:key="lay.id">{{ lay.price }}</span> -->
                                    <div
                                      class="text-4"
                                      v-if="
                                        this.eventLiabiliity[
                                          market.description.marketName
                                        ] &&
                                        runners?.description?.runnerName !=
                                          'Tie' &&
                                        market.description.marketName ===
                                          'Match Odds'
                                      "
                                    >
                                      <span
                                        v-if="
                                          this.eventLiabiliity?.[
                                            market.description.marketName
                                          ]?.[
                                            runners?.description?.runnerName
                                          ] &&
                                          this.isNumberOrString(
                                            market.description.marketName,
                                            runners?.description?.runnerName
                                          )
                                        "
                                        :class="`${
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][runners?.description?.runnerName] >=
                                          0
                                            ? 'text-success'
                                            : 'text-danger'
                                        }`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][runners?.description?.runnerName] !=
                                          "0.00"
                                            ? this.eventLiabiliity[
                                                market.description.marketName
                                              ][
                                                runners?.description?.runnerName
                                              ]
                                            : ""
                                        }}
                                      </span>
                                      <span
                                        v-else
                                        :class="`${
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ]['away'] >= 0
                                            ? 'text-success'
                                            : 'text-danger'
                                        }`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ]["away"] != "0.00"
                                            ? this.eventLiabiliity[
                                                market.description.marketName
                                              ]["away"]
                                            : ""
                                        }}
                                      </span>
                                    </div>

                                    <div
                                      class="text-4 fancy-bet"
                                      v-else-if="
                                        this.eventLiabiliity[
                                          market.description.marketName
                                        ]
                                      "
                                    >
                                      <!-- {{ this.eventLiabiliity }} -->

                                      <!-- {{  this.getFancyLaibility(market.description.marketName , runners?.description?.runnerName) }} -->
                                      <span
                                        v-if="
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[0]
                                        "
                                        :class="`text-danger`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[0]
                                        }}
                                      </span>
                                      <span
                                        v-if="
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[1]
                                        "
                                        :class="`text-success`"
                                      >
                                        {{
                                          this.eventLiabiliity[
                                            market.description.marketName
                                          ][
                                            runners?.description?.runnerName
                                          ]?.[1]
                                        }}
                                      </span>
                                    </div>
                                    <div
                                      class="text-4"
                                      v-if="
                                        market.description.bettingType !==
                                        'ODDS'
                                      "
                                    >
                                      <button
                                        class="hidden-sm hidden-xs btn btn-danger btn-sm"
                                        @click="
                                          this.openBookEventModel(
                                            market.marketId,
                                            market.description.marketName,
                                            runners?.description?.runnerName
                                          )
                                        "
                                      >
                                        Book
                                      </button>
                                    </div>
                                    <span
                                      style="visibility: hidden"
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[2]
                                          ?.price
                                          ? '6'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[2]?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 2,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[2]?.price
                                              ? '6'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span class="zoom">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[2]?.price ?? " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      style="visibility: hidden"
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[1]
                                          ?.price
                                          ? '6'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[1]?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 1,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[1]?.price
                                              ? '6'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span class="zoom">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[1]?.price ?? " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToBack?.[0]
                                          ?.price
                                          ? '6'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange
                                            ?.availableToBack?.[0]?.price,
                                          'Lay',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 0,
                                            stack: 'Back',
                                            btn_number: runners?.exchange
                                              ?.availableToBack?.[0]?.price
                                              ? '6'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom">
                                        {{
                                          runners?.exchange
                                            ?.availableToBack?.[0]?.price ?? " "
                                        }}</span
                                      >
                                    </span>
                                    <!-- <span class="voice-icon voice">
                                      <a href="javascript:void(0)"><img :src="`${publicPath}assets/img/voice.svg`" alt="" /></a>
                                    </span> -->
                                    <span
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToLay?.[0]
                                          ?.price
                                          ? '1'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[0]
                                            ?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 0,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[0]?.price
                                              ? '1'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span
                                        v-if="
                                          market?.marketDefinition?.status &&
                                          market?.marketDefinition?.status ===
                                            'SUSPENDED'
                                        "
                                        class="zoom suspended"
                                      >
                                        Suspended
                                      </span>
                                      <span v-else class="zoom">
                                        {{
                                          runners?.exchange?.availableToLay?.[0]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      style="visibility: hidden"
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToLay?.[1]
                                          ?.price
                                          ? '1'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[1]
                                            ?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 1,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[1]?.price
                                              ? '1'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span class="zoom">
                                        {{
                                          runners?.exchange?.availableToLay?.[1]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                    </span>

                                    <span
                                      style="visibility: hidden"
                                      :class="`text-btn text-btn-${
                                        runners?.exchange?.availableToLay?.[2]
                                          ?.price
                                          ? '1'
                                          : '3'
                                      }`"
                                      @click="
                                        openBetModel(
                                          `match-${indexM}runner-${indexR}`,
                                          runners?.exchange?.availableToLay?.[2]
                                            ?.price,
                                          'Back',
                                          runners?.description?.runnerName,
                                          market.description.marketName,
                                          {
                                            market: indexM,
                                            runner: indexR,
                                            position: 2,
                                            stack: 'Lay',
                                            btn_number: runners?.exchange
                                              ?.availableToLay?.[2]?.price
                                              ? '1'
                                              : '3',
                                          },
                                          market.description.marketType,
                                          market.marketId,
                                          market?.marketDefinition?.status,
                                          runners
                                        )
                                      "
                                    >
                                      <span class="zoom">
                                        {{
                                          runners?.exchange?.availableToLay?.[2]
                                            ?.price ?? " "
                                        }}
                                      </span>
                                    </span>
                                    <!-- <span class="text-btn text-btn-6" v-for=" (back, index) in runners.exchange?.availableToBack" v-bind:index="index" v-bind:key="back.id">{{ back.price }}</span> -->

                                    <!-- <span class="text-btn text-btn-4">-</span>
                                      <span class="text-btn text-btn-5">2.11</span>
                                      <span class="text-btn text-btn-6">0.6</span> -->
                                  </div>
                                  <div
                                    class="tab-2-content-ammount-set-sec"
                                    :id="`match-${indexM}runner-${indexR}`"
                                    :style="{
                                      background: this.modelBackGround,
                                    }"
                                    :key="this.refreshBetModel"
                                  >
                                    <div 
                                      class="close-btn"
                                      @click="clearBet(event, true)"
                                    >X</div>
                                    <div class="container p-0">
                                      <div
                                        class="tab-2-content-ammount-set-sec-outer d-flex flex-wrap"
                                      >
                                      <div 
                                        class="tab-2-content-top d-flex flex-wrap"
                                      >
                                          <h3
                                            class="tab-2-content-ammount-set-sec-head mb-3 mb-xl-0"
                                          >
                                            Bet Slip
                                          </h3>
                                          <div  v-if="
  market.description.bettingType ==
  'ODDS' && market.description.marketType ==
  'MATCH_ODDS'
" class="mr-5"><bold>{{ eventData[0]?.markets[0]?.runners[0]?.description?.runnerName }}: </bold> {{ this.team1_odds_amount }}<span :id="`runner-${indexM}`"></span>,</div>
                                          <div v-if="
  market.description.bettingType ==
  'ODDS' && market.description.marketType ==
  'MATCH_ODDS'
" class="mr-5"><bold>{{ eventData[0]?.markets[0]?.runners[1]?.description?.runnerName }}:  {{   this.team2_odds_amount }}  </bold><span :id="`runner-${indexR}`"></span>,</div>

                                          <div v-if="
  (eventData[0]?.markets[0]?.runners[2]) && ( market.description.bettingType ==
  'ODDS' && market.description.marketType ==
  'MATCH_ODDS')
" class="mr-5"><bold>{{ eventData[0]?.markets[0]?.runners[2]?.description?.runnerName }}:  {{   this.the_draw_amount }}  </bold><span :id="`runner-${indexR}`"></span>,</div>
                                          <div><bold>Balance: {{ this.auth.balance }}</bold>
                                          </div>
                                      </div>
                                        <div
                                          class="tab-2-content-ammount-set-sec-content-con"
                                        >
                                          <div
                                            class="tab-2-content-ammount-set-sec-content-con-inner d-flex flex-nowrap"
                                          >
                                            <form
                                              onsubmit="return false"
                                              class="tab-2-content-ammount-set-sec-form d-flex text-start"
                                              @submit.prevent="placeBet()"
                                            >
                                              <div class="incdec-blk">
                                                <div class="incdec-btn">
                                                  <button
                                                    type="button"
                                                    @click="
                                                      this.userBetBhavIncrement()
                                                    "
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                                <input
                                                  class="btn-click-value-display"
                                                  :id="`match-${indexM}runner-${indexR}-price`"
                                                  type="text"
                                                  v-model="userBetBhav"
                                                  @input="
                                                    this.userChangedBhavFun()
                                                  "
                                                  @keydown.up.prevent="
                                                    this.userBetBhavIncrement()
                                                  "
                                                  @keydown.down.prevent="
                                                    this.userBetBhavDecrement()
                                                  "
                                                />

                                                <div class="incdec-btn">
                                                  <button
                                                    type="button"
                                                    @click="
                                                      this.userBetBhavDecrement()
                                                    "
                                                  >
                                                    -
                                                  </button>
                                                </div>
                                              </div>

                                              <input
                                                class="enter-bet-ammount"
                                                type="text"
                                                placeholder="Enter Bet Amount"
                                                v-model="userAmount"
                                                :ref="`amoutBetInput-${indexM}-${indexR}`"
                                                @input="onAmountInput"
                                              />
                                              <div class="bet-modal-user-pl">
                                                <span
                                                  :class="`${
                                                    this.selectedOption ==
                                                    'Back'
                                                      ? 'text-success'
                                                      : 'text-danger'
                                                  }`"
                                                  >{{
                                                    this.userProfitLoss
                                                  }}</span
                                                >
                                              </div>
                                              <input
                                                class="clear"
                                                type="button"
                                                value="Clear"
                                                @click="clearBet(event, false, indexM, indexR)"
                                              />
                                              <input
                                                class="place-bet"
                                                type="submit"
                                                value="Place Bet"
                                              />
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="tab-2-content-ammount-set-sec-outer-2 d-flex mt-3 flex-nowrap"
                                      >
                                        <span
                                          class="add-aamount-btn d-flex justify-content-between">
                                          <button class="incdec-minus-btn incdec-btn px-6 py-2 bg-danger" @click="
                                            this.betAmountUpdate(this.button_1, '-')
                                          "
                                          >-</button>
                                         <span 
                                          style="cursor: pointer;width:100%;height: 100%;display: flex;align-items: center;justify-content: center;"
                                          @click="this.betAmountUpdate(this.button_1, '+')"
                                         >
                                           {{ this.button_1 }}
                                         </span>
                                          
                                          <button class="px-2 py-2" @click="
                                            this.betAmountUpdate(this.button_1, '+')
                                          "
                                          >+</button>
                                          </span>
                                        <span
                                          class="add-aamount-btn d-flex justify-content-between">
                                          <button class=" incdec-minus-btn incdec-btn px-6 py-2 bg-danger" @click="
                                            this.betAmountUpdate(this.button_2, '-')
                                          "
                                          >-</button>
                                         
                                          <span 
                                           class="incdec-span"
                                          @click="this.betAmountUpdate(this.button_2, '+')"
                                         >
                                           {{ this.button_2 }}
                                         </span>
                                          
                                          <button class="px-2 py-2" @click="
                                            this.betAmountUpdate(this.button_2, '+')
                                          "
                                          >+</button>
                                          
                                          </span
                                        >
                                        <span
                                          class="add-aamount-btn d-flex justify-content-between">
                                          
                                          <button class="incdec-minus-btn incdec-btn px-6 py-2 bg-danger" @click="
                                            this.betAmountUpdate(this.button_3, '-')
                                          "
                                          >-</button>
                                         
                                          <span 
                                            class="incdec-span"
                                            @click="this.betAmountUpdate(this.button_3, '+')"
                                          >
                                            {{ this.button_3 }}
                                          </span>
                                          
                                          <button class="px-2 py-2" @click="
                                            this.betAmountUpdate(this.button_3, '+')
                                          "
                                          >+</button>
                                          
                                          </span
                                        >
                                        <span
                                          class="add-aamount-btn d-flex justify-content-between">
                                          <button class=" incdec-minus-btn incdec-btn px-6 py-2 bg-danger" @click="
                                            this.betAmountUpdate(this.button_4, '-')
                                          "
                                          >-</button>
                                         
                                          <span 
                                            class="incdec-span"
                                            @click="this.betAmountUpdate(this.button_4, '+')"
                                          >
                                            {{ this.button_4 }}
                                          </span>
                                          
                                          <button class="px-2 py-2" @click="
                                            this.betAmountUpdate(this.button_4, '+')
                                          "
                                          >+</button>
                                          
                                          </span
                                        >
                                        <span
                                          class="add-aamount-btn d-flex justify-content-between">
                                          <button class=" incdec-minus-btn incdec-btn px-6 py-2 bg-danger" @click="
                                            this.betAmountUpdate(this.button_5, '-')
                                          "
                                          >-</button>
                                          <span 
                                            class="incdec-span"
                                            @click="this.betAmountUpdate(this.button_5, '+')"
                                          >
                                            {{ this.button_5 }}
                                          </span>
                                          <button class="px-2 py-2" @click="
                                            this.betAmountUpdate(this.button_5, '+')
                                          "
                                          >+</button>
                                          
                                          </span
                                        >
                                        <!-- <span class="add-aamount-btn" @click="this.betAmountUpdate(1000)">{{this.button_1}}</span> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <hr /> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right active event-right-section">
                  <div class="right-btn text-right py-2">
                    <router-link
                      :to="{ name: 'bets' }"
                      class="text-white btn btn-primary mr-2 mb-4"
                      target="_blank"
                    >
                      Max
                    </router-link>

                    <button
                      class="btn-collapse minus broadcast-collapse-btn"
                      v-if="!this.eventSideFull"
                      @click="this.rightSectionCollapse()"
                    ></button>
                  </div>
                  <div class="p-0">
                    <div class="live-broadcast-accordion-sec">
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              <img
                                class="live-broadcast-icon"
                                :src="`${publicPath}assets/img/live-broadcast-icon-dark.svg`"
                                alt=""
                              />

                              Live Broadcast
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              No Brodcast Available for Now.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingTwo">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              My Bets
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            class="accordion-collapse collapse show"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              <div
                                class="live-broadcast-accordion-sec-table w-100"
                              >
                                <!-- <div
                                  class="d-flex justify-content-between align-items-center text-center"
                                >
                                  <span style="font-weight: 600"
                                    >Unmatched Bets</span
                                  >
                                  <span class="cancel-all text-end"
                                    >Cancel All</span
                                  >
                                </div>
                                <div
                                  class="d-flex justify-content-between align-items-center text-center"
                                  style="font-weight: 500"
                                >
                                  <span>Runner Name</span>
                                  <span>Bet Price</span>
                                  <span>Bet Size</span>
                                </div> -->

                                <div
                                  class=""
                                  :key="this.eventBetListRefreshKey"
                                >
                                <span style="font-weight: 600">
                                  Unmatched Bets
                                </span>
                                <span
                                  class="cancel-all text-end"
                                  style="cursor: pointer"
                                  @click="cancelAllUnMatchedBets()"
                                  >Cancel All</span
                                >

                                  <span
                                    style="font-weight: 600"
                                    v-if="this.eventUnmatchedTotalAmount"
                                  >
                                    Total : {{ this.eventUnmatchedTotalAmount }}
                                  </span>
                                  <span
                                    style="font-weight: 600"
                                    v-if="this.unmacthedEventAverage"
                                  >
                                    Average : {{ this.unmacthedEventAverage }}
                                  </span>
                                </div>
                                <table class="w-100 text-start">
                                  <tr>
                                    <th>Runner Name</th>
                                    <th>Bet Price</th>
                                    <th>Bet Size</th>
                                  </tr>
                                  <tr
                                    v-for="(
                                      bat, index
                                    ) in userUnmatchedEventBats"
                                    v-bind:index="index"
                                    v-bind:key="index"
                                    :style="{
                                      backgroundColor: getBackgroundColor(
                                        bat.selected_option
                                      ),
                                    }"
                                  >
                                    <td>
                                      <span
                                        style="cursor: pointer"
                                        @click="cancelUnMatchedBets(bat.id)"
                                        ><i class="fa fa-trash"></i
                                      ></span>
                                      {{ bat?.stack_type + ":" + bat?.team }}
                                    </td>
                                    <td>{{ bat?.bhav }}</td>
                                    <td>{{ bat?.amount }}</td>
                                  </tr>
                                </table>
                                <div
                                  class="mt-3"
                                  :key="this.eventBetListRefreshKey"
                                >
                                  <span style="font-weight: 600">
                                    Matched Bets
                                  </span>
                                  <!-- <span
                                    style="font-weight: 600"
                                    v-if="this.eventTotalAmount"
                                  >
                                    Total : {{ this.eventTotalAmount }}
                                  </span>
                                  <span
                                    style="font-weight: 600"
                                    v-if="this.macthedEventAverage"
                                  >
                                    Average : {{ this.macthedEventAverage }}
                                  </span> -->
                                </div>
                                <table class="w-100 text-start">
                                  <tr>
                                    <th>Match Name</th>
                                    <th>Runner Name</th>
                                    <th>Bet Price</th>
                                    <th>Profit/Loss</th>
                                    <th>Bet Size</th>
                                  </tr>
                                  <tr
                                    v-for="(bat, index) in userEventBats"
                                    v-bind:index="index"
                                    v-bind:key="index"
                                    :style="{
                                      backgroundColor: getBackgroundColor(
                                        bat.selected_option
                                      ),
                                    }"
                                  >
                                  {{ this.team1_name = bat?.team1_name }}
                                  {{ this.team2_name = bat?.team2_name }}

                                    <td>
                                      {{ bat?.stack_type + ":" + bat?.team }}
                                    </td>
                                    <td>{{ bat?.bhav }}</td>
                                    <!-- <td  class="text-right">{{ parseFloat((bat?.bhav * bat?.amount) - bat?.amount).toFixed(2) }}</td> -->
                                    <td>{{ bat?.pl }}</td>
                                    <td>
                                      {{ bat?.amount }}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              Score
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              <div
                                class="live-broadcast-accordion-sec-table w-100"
                              >
                                <div class="mt-3">
                                  <span style="font-weight: 600"> Team </span>
                                </div>
                                <div
                                  v-for="(score, index) in eventScore.home"
                                  v-bind:index="index"
                                  v-bind:key="index"
                                >
                                  Over {{ score?.score.overs }}
                                  {{ score?.name }} {{ score?.score.run }}/
                                  {{ score?.score.wickets }}
                                </div>
                                <div class="mt-3">
                                  <span style="font-weight: 600"> Team </span>
                                </div>
                                <div
                                  v-for="(score, index) in eventScore.away"
                                  v-bind:index="index"
                                  v-bind:key="index"
                                >
                                  Over {{ score?.score.overs }}
                                  {{ score?.name }} {{ score?.score.run }}/
                                  {{ score?.score.wickets }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="accordion-item">
                          <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              <code>.accordion-flush</code>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <!-- <section class="card p-3 border-warning">
                      We will display Session Bets separately soon!
                    </section> -->
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div
          class="tab-pane fade"
          id="pills-ezugi"
          role="tabpanel"
          aria-labelledby="pills-ezugi-tab"
          tabindex="0"
        >
          <h1 class="text-center m-5 p-0" style="font-size: 30px">Hello</h1>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal bookbet-modal fade"
    id="bookEventModel"
    tabindex="-1"
    aria-labelledby="bookEventModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="bookbet-modal-blk">
          <div class="title">
            <h3>
              {{ this.eventBookDetail.market }} -
              {{ this.eventBookDetail.runner }}
            </h3>
            <div class="btnbox">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="bookbet-modal-table">
            <div class="bokbet-row-title">
              <div class="bokbet-col">Bet Price</div>
              <div class="bokbet-col">Option</div>
              <div class="bokbet-col">Bet Size</div>
            </div>
            <div v-if="this.eventBookDetail.data">
              <div
                class="bokbet-row"
                v-for="(bat, index) in this.eventBookDetail.data"
                v-bind:index="index"
                v-bind:key="index"
              >
                <div class="bokbet-col">{{ bat.bhav }}</div>
                <div class="bokbet-col">{{ bat.selected_option }}</div>
                <div class="bokbet-col">{{ bat.amount }}</div>
              </div>
            </div>
            <div class="bokbet-row" v-else>
              <div class="bokbet-col bokbet-col-big">No Bet Avilable</div>
            </div>
          </div>
          <div class="p-2">
            <h2 class="title">Below is the sample data, not real.</h2>
            <br />
          </div>
          <div class="bookbet-modal-table">
            <div class="bokbet-row-title">
              <div class="bokbet-col">Score</div>
              <div class="bokbet-col">Position</div>
            </div>
            <div v-if="this.bookData">
              <div
                class="bokbet-row"
                v-for="(item, index) in this.bookData"
                v-bind:index="index"
                v-bind:key="index"
              >
                <div class="bokbet-col bokbet-right">{{ item.score }}</div>
                <div class="bokbet-col bokbet-right">{{ item.position }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade game-rule-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="title">
          <h3>Market Rules</h3>
          <div class="btnbox">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div
          class="game-rule-modal-content"
          v-html="eventRule"
          :key="ruleRefreshKey"
        ></div>
      </div>
    </div>
  </div>
</template>

<style>
/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
} */
.tab-2-content-ammount-set-sec {
  width: 100%;
  margin: 20px 0;
}
.score {
  font-size: 25px !important;
  font-weight: 1000 !important;
}
.tab-2-content-sec-1-head.score {
  min-width: 20%;
}
.tab-2-content-sec-1-head.score.vs {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .tab-2-content-sec-1 .item-con-inner-con-1 { width: 71% !important; padding-right: 10px; } */

.bookbet-modal .modal-dialog {
  max-width: 650px;
}
.bookbet-modal-blk {
  width: 100%;
  display: block;
  padding: 20px;
}
.bookbet-modal-blk > .title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bokbet-row-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #bdbdbd;
}
.bokbet-row-title .bokbet-col {
  width: 33.33%;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
}
.bokbet-right {
  justify-content: right;
}
.bokbet-col.bokbet-col-big {
  width: 100% !important;
  justify-content: center;
}
.bokbet-row-title .bokbet-col + .bokbet-col {
  border-left: 1px solid #bdbdbd;
}
.bookbet-modal-table {
  border: 1px solid #bdbdbd;
  border-bottom: none;
}
.bookbet-modal-blk > .title h3 {
  font-weight: bold;
  font-size: 24px;
}
.bookbet-modal-blk .bokbet-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #bdbdbd;
}
.bookbet-modal-blk .bokbet-row .bokbet-col + .bokbet-col {
  border-left: 1px solid #bdbdbd;
}
.bookbet-modal-blk .bokbet-row:nth-child(even) {
  background-color: rgba(37, 112, 168, 0.1);
}
.bookbet-modal-blk .bokbet-row .bokbet-col {
  width: 33.33%;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
}

.game-rule-modal {
  border: none;
}
.game-rule-modal .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bdbdbd;
  padding: 10px;
}
.game-rule-modal-content {
  padding: 20px 30px;
}

.game-rule-modal ol {
  list-style: auto !important;
}
.rule-btn {
  cursor: pointer;
}
.rule-btn:hover {
  text-decoration: underline;
}
.incdec-blk {
  display: flex;
  align-items: center;
  justify-content: center;
}
.incdec-blk input {
  border: 2px solid transparent;
  padding: 5px 5px;
  border-radius: 5px;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  background: #fff;
  border: 1px solid #e5e5e5;
  flex-basis: 100%;
  min-width: 0;
  max-width: 80px;
}
.incdec-blk button {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove_draw{
  display: flex;
  align-items:center;
  justify-content: space-between;
  width: 100%;
}
</style>

<script>
// import UserHome from './user/UserHome.vue'
import HeaderComponent from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import SideBet from "@/components/SideBet.vue";
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
import $ from "jquery";
// import axios from "axios";
import io from "socket.io-client";

export default {
  name: "EventInner",
  components: {
    HeaderComponent,
    SideBet,
    SideBar,
  },
  props: [
    'event_type'
  ],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      eventData: [],
      team: {
        team1: {
          runner_name: "",
          selection_id: "",
        },
        team2: {
          runner_name: "",
          selection_id: "",
        },
      },
      tempI: 0,
      event_id: "",
      selection_id: "",
      marketId: "",
      selectedPrice: 0,
      selectedOption: "",
      selectedTeam: "",
      selectedstack_type: "",
      userAmount: "",
      userEventBats: [],
      has_line_bet: false,
      loading: false,
      userSelectBat: {},
      bookData: [],
      selectedBatID: "",
      modelBackGround: "#72BBEF",
      button_1: "",
      button_2: "",
      button_3: "",
      button_4: "",
      button_5: "",
      defaultAmount: "",
      eventLiabiliity: {},
      eventBookDetail: {},
      eventRule: "",
      eventTotalAmount: "",
      ruleRefreshKey: 0,
      refreshHeaderKey: 0,
      eventSportID: "",
      isSubscribed: false,
      eventRefreshKey: 1,
      intervalId: null,
      userButtonLastPress: "",
      userBetBhav: 0,
      isDrawPresent: false,
      userChangedBhav: false,
      userUnmatchedEventBats: {},
      eventUnmatchedTotalAmount: "",
      macthedEventAverage: "",
      unmacthedEventAverage: "",
      eventSideBarData: {},
      userGame: {},
      userSelectedMarket: null,
      eventTieData: {},
      eventBetListRefreshKey: 0,
      eventSideFull: false,
      userProfitLoss: 0,
      refreshBetModel: 0,
      is_event_api_okay: 1,
      eventScore: {
        home: [],
        away: [],
      },
      checktypesinclude: [
        "MATCH_ODDS",
        "1_INNING_6_OVR_LINE",
        "1_INNING_10_OVR_LINE",
        "1_INNING_15_OVR_LINE",
        "1_INNING_20_OVR_LINE",
        "1_INNINGS_RUNS_LINE",
        "2_INNING_6_OVR_LINE",
        "2_INNING_10_OVR_LINE",
        "2_INNING_15_OVR_LINE",
        "2_INNING_20_OVR_LINE",
      ],
      eventtype: this.marketNames,
      bet_index: null,
      socket: null,
      buffer: "",
      eventOddsLiability: {},
      team1_odds_amount:0,
      team2_odds_amount:0,
      team1_name:null,
      team2_name:null,
      match_time:null,
      event_type_data:null,
      the_draw_amount:0,
    };
  },
  // components: {
  // 	UserHome,AdminHome
  // },
  created: function () {
    this.auth = SessionService.getUser();
    this.event_id = this.$route.params.id;
    // this.event_type_data = window.history.state.event_type;
    this.event_type_data = this.$route.params.event_type;
    this.connectSocket();

    // window.addEventListener('beforeunload', () =>{
    //   this.cleanUpWebsocket()
    // });

    this.getUserStackButtonData();
    

    this.getEventBatList();
    
    setInterval(() => {
      this.getEventBatList();
      
      setTimeout(() => {
        this.getEventLiabiliityData();
      }, 1000)
    }, 10000)

    // this.$socket.emit("EventID", this.event_id);
    this.getEventLiabiliityData();
    // this.getEventRule();

    this.isSubscribed = true;
    document.addEventListener("keydown", this.handleKeyPress);
    this.userGames();

    // $(document).ready(function () {
    //   $('.sidenavCollapse').on('click', function () {
    //     console.log('here side nav')
    //     $('.sidepanel').toggleClass('active');
    //   });
    // });

    window.addEventListener("keyup", this.handleKeyUp);
  },
  unmounted() {
    document.removeEventListener("keydown", this.handleKeyPress);
    this.clearAllIntervals();
  },
  beforeUnmount() {
    this.clearAllIntervals();

    this.cleanUpWebsocket()
  },
  methods: {
    connectSocket() {

      this.socket = io.connect(`${process.env.VUE_APP_SOCKET_URL}`);

      this.socket.emit(`eventId`, this.$route.params.id);
      this.refreshHeaderKey += 1;

      // setInterval(() => {
      //   this.socket.emit(`eventId`, this.$route.params.id);
      // }, 1000);
      

      this.socket.on(`refreshUnmatchedBets-${this.auth.id}-${this.event_id}`, (response) => {
        console.log('Unmatched Bet Refreshed!!', response);
        this.getEventBatList()
      });

      this.socket.on(`eventId-${this.event_id}`, (response) => {
        if( !response.status ){
          response.event_id = this.event_id;
        }
        this.fetchEventData(response);
        try {
          if (response.data.length > 0) {
            this.is_event_api_okay = 1;
          } else {
            this.is_event_api_okay = 0;
          }
        } catch (error) {
          this.is_event_api_okay = 0;
        }
        // this.refreshHeaderKey += 1;
        // this.eventBetListRefreshKey += 1;
        // this.refreshBetModel += 1
      });


      this.socket.on(`dataForEvent-${this.$route.params.id}`, (response) => {
        const responseData = JSON.parse(response);
        const bufferDataArray = responseData.data;
        const uint8Array = new Uint8Array(bufferDataArray);
        const decoder = new TextDecoder("utf-8");
        const dataJSON = decoder.decode(uint8Array);
        const data = JSON.parse(dataJSON);
        if (data?.mc) {
            this.newFetchEventData(data.mc);
        }
        // if(dataJSON.includes("SUB_IMAGE")) {
        //   this.buffer += dataJSON
        //   return;
        // } else {
        //   if (this.buffer && this.buffer !== '') {
        //     const data = JSON.parse(this.buffer);
        //     this.buffer = "";
        //     console.log('datafromBuffer: ', data);
        //     if (data?.mc) {
        //       this.newFetchEventData(data.mc);
        //     }
        //   } else {
        //     const data = JSON.parse(dataJSON);
        //     if (data?.mc) {
        //       this.newFetchEventData(data.mc);
        //     }
        //   }
        // }
        
      });
      // Event listener for 'disconnect' event
      this.socket.on('disconnect', () => {
        console.log('Socket disconnected');
        // Attempt reconnection
        // this.reconnectSocket();
      });

      this.socket.on('connect', () => {
        // this.socket.connect();
        console.log('Socket connected');
      });

      this.socket.on('close', function() {
          console.log("closed")
      });
    },
    reconnectSocket() {
      // Reconnect to the socket server
      this.socket.connect();
      this.connectSocket();
    },
    cleanUpWebsocket(){

      // const unmatchedBets = this.userUnmatchedEventBats;
      // if(unmatchedBets.length <= 0 && !this.has_line_bet){
      //     this.socket.emit(`unsubscribeFromEvent`, this.event_id); //stop listening to event
      //     // this.socket.disconnect(); //Socket disconnected before unload
      //     console.log('Socket disconnected before unload');
      // }
    
    },
    cancelUnMatchedBets(id) {
      const data = {
        id: id,
      };
      DataService.cancelUnmatchedBets(data)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            if (data.status) {
              this.getEventBatList();
              this.getEventLiabiliityData();
              this.refreshHeaderKey += 1;
              this.$toast.success(data.message);
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
          this.$toast.error("Please Try Again.");
        });
    },
    handleKeyUp(event) {
      // Check if the pressed key is ESC
      if (event.key === "Escape") {
        $(".tab-2-content-ammount-set-sec").fadeOut(10);
        // You can perform your desired actions here
      }
    },
    cancelAllUnMatchedBets() {
      const data = {
        event_id: this.event_id,
      };
      DataService.cancelAllUnmatchedBets(data)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            if (data.status) {
              this.getEventBatList();
              this.getEventLiabiliityData();
              this.refreshHeaderKey += 1;
              this.$toast.success(data.message);
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
          this.$toast.error("Please Try Again.");
        });
    },
    getEventScore() {
      // axios
      //   .post(`${process.env.VUE_APP_SOCKET_URL}/event-score/${this.event_id}`)
      //   .then((response) => {
      //     const data = response.data.data;
      //     const status = response.data.data;
      //     if (status) {
      //       // console.log('data' , data)
      //       this.eventScore.home = data.home;
      //       this.eventScore.away = data.away;
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
    },
    rightSectionCollapse() {
      // $('.right').toggleClass('active');
      $(".broadcast-collapse-btn").toggleClass("right-icon");
      $(".broadcast-collapse-btn").toggleClass("minus");
      $(".event-right-section").toggleClass("broadcast-active");
      $(".left").toggleClass("right-broadcast-active");
      $(".sidebarCollapse").toggleClass("market-btn-position");
      $(".sidebarCollapse").removeClass("minus");
      if ($(".right").hasClass("active") == false) {
        $(".right").addClass("active");
        $(".sidebarCollapse").removeClass("right-icon");
      }
    },
    eventListBar() {
      $(".right").toggleClass("active");
      $(".sidebarCollapse").toggleClass("right-icon");
      $(".sidebarCollapse").toggleClass("minus");
      if (!$(".broadcast-collapse-btn").hasClass("minus")) {
        $(".broadcast-collapse-btn").addClass("minus");
      }
      if ($(".event-right-section").hasClass("broadcast-active")) {
        $(".event-right-section").removeClass("broadcast-active");
        $(".sidebarCollapse").removeClass("market-btn-position");
      }
      this.eventSideFull = !this.eventSideFull;
    },
    showAllMarket() {
      this.userSelectedMarket = null;
      this.getEventBatList();
    },
    showMarket(market) {
      this.userSelectedMarket = market;
      this.getEventBatList();
    },
    userGames() {
      DataService.getUserAllowedGames()
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            const data = response.data.data;
            this.userGame = data;
          }
        })
        .catch((e) => {
          console.error("error", e);
        });
    },
    userChangedBhavFun() {
      this.userChangedBhav = true;
      this.userBetBhav = this.userBetBhav.replace(/[^0-9.]/g, "");
      let plamount = this.userBetBhav * this.userAmount;
      if (this.userSelectBat?.market) {
        this.userChangedBhav = false;
        plamount = parseFloat(this.userAmount);
      }
      this.userProfitLoss = plamount.toFixed(2);
      this.getEventoddsLiability();
    },
    userBetBhavIncrement() {
      if (this.userSelectBat?.market > 0) return; //dont increment if line bets
      let finalAmount = 0;
      let increment = 0.1;
      if (this.userBetBhav >= 99) {
        increment = 10;
      } else if (this.userBetBhav >= 9) {
        increment = 1;
      } else if (this.userBetBhav >= 1) {
        increment = 0.1;
      } else {
        increment = 0.01;
      }
      finalAmount = parseFloat(this.userBetBhav) + increment;
      const resultString = finalAmount.toFixed(2);
      this.userChangedBhav = true;
      this.userBetBhav = resultString;
      // let plamount = (this.userBetBhav - 1) * this.userAmount;
      let plamount = this.userBetBhav * this.userAmount;
      if (this.userSelectBat?.market) {
        plamount = parseFloat(this.userAmount);
      }
      this.userProfitLoss = plamount.toFixed(2);
      // $(`.enter-bet-ammount`).focus();
      this.getEventoddsLiability();
    },
    userBetBhavDecrement() {
      if (this.userSelectBat?.market > 0) return; //dont decrement if line bets

      let finalAmount = 0;
      let decrement = 0.1;
      if (this.userBetBhav >= 99) {
        decrement = 10;
      } else if (this.userBetBhav >= 9) {
        decrement = 1;
      } else if (this.userBetBhav >= 1) {
        decrement = 0.1;
      } else {
        decrement = 0.01;
      }
      finalAmount = parseFloat(this.userBetBhav) - decrement;
      const resultString = finalAmount.toFixed(2);
      this.userChangedBhav = true;
      if (resultString > 0) {
        this.userBetBhav = resultString;
      }
      // let plamount = (this.userBetBhav - 1) * this.userAmount;
      let plamount = this.userBetBhav * this.userAmount;
      if (this.userSelectBat?.market) {
        plamount = parseFloat(this.userAmount);
      }
      this.userProfitLoss = plamount.toFixed(2);
      // $(`.enter-bet-ammount`).focus();
      this.getEventoddsLiability();
    },
    isNumberOrString(market, runner) {
      const data = this.eventLiabiliity[market];
      if (data && data[runner]) {
        return (
          typeof data[runner] === "number" || typeof data[runner] === "string"
        );
      }
    },
    getFancyLaibility(market, runner) {
      const data = this.eventLiabiliity[market];
      let html = "";
      if (data) {
        for (const key in data) {
          const cleanedKey = key.trim();
          const cleanedRunner = runner.trim();
          if (cleanedKey === cleanedRunner) {
            html += '<span class="text-danger">' + data[key]?.[0] + "</span>";
            html += '<span class="text-success">' + data[key]?.[1] + "</span>";
          }
        }
      }
      // return html
      console.log("html", html);
    },
    betUnmatchedUpdate(id) {
      DataService.updateBetUnmatched(id)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.getEventBatList();
          }
        })
        .catch((e) => {
          console.error("error", e);
        });
    },
    handleUnmatchedBets(data) {
      const unmatchedBets = this.userUnmatchedEventBats;
      if (unmatchedBets && unmatchedBets.length > 0) {
        unmatchedBets.map((betData) => {
          data[0]?.markets.map((markets) => {
            if (betData.stack_type == markets.description.marketName) {
              if (markets?.runners) {
                markets?.runners.map((runners) => {
                  if (betData.team == runners.description.runnerName) {
                    if (betData.selected_option == "Back") {
                      const back = runners?.exchange?.availableToBack;
                      if (back && back.length > 0) {
                        const betinPrice = back.find((value, index) => {
                          const bhav = parseFloat(betData.bhav);
                          if (
                            index == 0 &&
                            value.price >=1 && 
                            ((betData.bet_index >= 0 &&
                              betData.bet_index < back.length &&
                              bhav == value.price) ||
                              value.price >= bhav)
                          ) {
                            return true;
                          }
                        });
                        if (betinPrice) {
                          // this.betUnmatchedUpdate(betData.id);
                        }

                        // const value = back[0];
                        // const bhav = parseFloat(betData.bhav);
                        // if (
                        //   (betData.bet_index >= 0 &&
                        //     betData.bet_index < back.length &&
                        //     bhav == value.price) ||
                        //   value.price > bhav
                        // ) {
                        //   this.betUnmatchedUpdate(betData.id);
                        // }
                      }
                    }
                    if (betData.selected_option == "Lay") {
                      const lay = runners?.exchange?.availableToLay;
                      if (lay && lay.length > 0) {
                        const betinPrice = lay.find((value, index) => {
                          const bhav = parseFloat(betData.bhav);
                          if (
                            index == 2 &&
                            value.price >=1 && 
                            ((betData.bet_index >= 0 &&
                              betData.bet_index < lay.length &&
                              bhav == value.price) ||
                              bhav >= value.price)
                          ) {
                            return true;
                          }
                        });
                        if (betinPrice) {
                          // this.betUnmatchedUpdate(betData.id);
                        }
                        // const value = lay[2];
                        // const bhav = parseFloat(betData.bhav);
                        // if (
                        //   (betData.bet_index >= 0 &&
                        //     betData.bet_index < lay.length &&
                        //     bhav == value.price) ||
                        //   bhav > value.price
                        // ) {
                        //   this.betUnmatchedUpdate(betData.id);
                        // }
                      }
                    }
                  }
                });
              }
            }
          });
        });
      }
    },
    ensureMatchOddsOnTop(a) {
      // -1 should be placed at the beginning
      if (a?.description?.marketType === "MATCH_ODDS") return -1;
      // -1 should be placed at the end
      // return 1;
      // For other cases, maintain the original order
      return 0;
      // Otherwise, sort based on the priority property inside the data object
      // return a.data.priority - b.data.priority;
    },
    arrangeIndexForLay(data) {
      data[0]?.markets?.forEach((market) => {
        if (market && market?.runners?.length) {
          market.runners.forEach((runner) => {
            const atl = runner?.exchange?.availableToLay;
            if (runner && atl?.length) {
              if (atl.length > 2) {
                //0,1, 2
                return;
              } else if (atl.length == 2) {
                //0, 1
                const zeroth = atl[0];
                const first = atl[1];
                atl[2] = first;
                atl[1] = zeroth;
                atl[0] = undefined;
              } else if (atl.length == 1) {
                //0
                const zeroth = atl[0];
                atl[2] = zeroth;
                atl[1] = undefined;
                atl[0] = undefined;
              }
            }
          });
        }
      });
    },
    fetchEventData(response) {
      try {
        const data = response.data;
        const status = response.status;
        console.log('=> RESPONSE DATA <=');
        console.log( data );

        if(data){
          data[0]?.markets?.sort(this.ensureMatchOddsOnTop);
          // this.arrangeIndexForLay(data);
  
          if (status) {
            this.eventData = [];
            this.eventData = data;
            let sideBarData = {
              name: data[0]?.event?.name,
              market: [],
            };
            const runner1 = this.eventData[0]?.markets[0]?.runners[0];
            const runner2 = this.eventData[0]?.markets[0]?.runners[1];
            this.team.team1 = {
              runner_name: runner1?.description?.runnerName,
              selection_id: runner1?.selectionId,
            };
  
            this.team.team2 = {
              runner_name: runner2?.description?.runnerName,
              selection_id: runner2?.selectionId,
            };
  
            if (data[0].markets) {
              data[0].markets.map((markets, index) => {
                const marketName = markets?.description?.marketName;
                // const marketTypes = markets?.description?.marketType;
                //console.log("marketTypes: ", marketTypes);
  
                // if (this.checktypesinclude.includes(marketTypes)) {
                sideBarData.market.push({
                  index,
                  name: marketName,
                });
                // }
  
                // if(marketName == 'Match Odds Including Tie' || marketName == 'Tied Match'){
                //   eventTieData.push( {
                //     index ,
                //     name : marketName
                //   })
                // }
              });
              this.handleUnmatchedBets(data);
            }
            this.eventSideBarData = sideBarData;
  
            this.eventSportID = data[0]?.event?.eventTypeId;
            if (
              this.userSelectBat &&
              Object.keys(this.userSelectBat).length > 0
            ) {
              let userSelectValue = "";
              if (this.userSelectBat.stack == "Back") {
                userSelectValue =
                  this.eventData[0]?.markets[this.userSelectBat.market]?.runners[
                    this.userSelectBat.runner
                  ]?.exchange?.availableToBack?.[this.userSelectBat.position];
              } else if (this.userSelectBat.stack == "Lay") {
                userSelectValue =
                  this.eventData[0]?.markets[this.userSelectBat.market]?.runners[
                    this.userSelectBat.runner
                  ]?.exchange.availableToLay?.[this.userSelectBat.position];
              }
              this.selectedPrice = userSelectValue?.price;
              $(this.selectedBatID).html(this.selectedPrice);
            }
          }
        } else {
          DataService.getCompletedEventBetsList(response)
            .then((response) => {
              if (response.status == 200) {
                const data = response.data;
                if (data.status) {
                  this.team1_name = data.team1_name;
                  this.team2_name = data.team2_name;
                  this.match_time = data.match_time;
                }
              }
            })
            .catch(() => {
              this.$toast.error("Please Try Again.");
            });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    newFetchEventData(mc) {
      //mc market Change
      try {
        if (mc && mc.length) {
          mc.forEach((mcItem) => {
            const { id: marketId, rc, marketDefinition } = mcItem;
            // const { id: marketId, rc } = mcItem;
            // const marketDefinition = {
            //   bspMarket: false,
            //   turnInPlayEnabled: true,
            //   persistenceEnabled: true,
            //   marketBaseRate: 5,
            //   eventId: "33127912",
            //   eventTypeId: "4",
            //   numberOfWinners: 0,
            //   bettingType: "LINE",
            //   marketType: "1_INNING_3_OVR_LINE",
            //   marketTime: "2024-03-27T14:00:00.000Z",
            //   suspendTime: "2024-03-27T14:00:00.000Z",
            //   bspReconciled: false,
            //   complete: true,
            //   inPlay: true,
            //   crossMatching: false,
            //   runnersVoidable: false,
            //   numberOfActiveRunners: 1,
            //   betDelay: 3,
            //   status: "SUSPENDED",
            //   runners: [
            //     {
            //       status: "ACTIVE",
            //       sortPriority: 1,
            //       id: 15316,
            //     },
            //   ],
            //   regulators: ["MR_INT"],
            //   countryCode: "GB",
            //   discountAllowed: true,
            //   timezone: "GMT",
            //   openDate: "2024-03-27T14:00:00.000Z",
            //   version: 5820903187,
            //   lineMaxUnit: 999.5,
            //   lineMinUnit: 0.5,
            //   lineInterval: 1,
            //   priceLadderDefinition: {
            //     type: "LINE_RANGE",
            //   },
            // };

            // this.tempI = this.tempI + 1;

            if (marketDefinition) {
              // console.log("marketDefinitioon: ", marketDefinition);
              const { marketType } =
                marketDefinition;
              // console.log("status: ", status);
              // console.log("marketType: ", marketType);
              // console.log("suspendTime: ", suspendTime);
              // console.log("inPlay: ", inPlay);
              // console.log("complete: ", complete);

              this.eventData[0]?.markets.forEach((marketItem) => {
                // console.log(
                //   "marketItem?.description?.marketType: ",
                //   marketItem?.description?.marketType
                // );
                // if (
                //   this.tempI == 8 &&
                //   marketItem?.description?.marketType === marketType
                // ) {
                if (marketItem?.description?.marketType === marketType) {
                  // console.log("marketTypeif: ", marketType);
                  marketItem.marketDefinition = marketDefinition;
                  // console.log(
                  //   "marketItem.marketDefinition: ",
                  //   marketItem.marketDefinition
                  // );
                }
              });
              // console.log(
              //   "this.eventData[0]?.markets: ",
              //   this.eventData[0]?.markets
              // );
              // will change the event data from here
              // console.log(
              //   "marketDefinitionfromNewFetchData: ",
              //   marketDefinition
              // );
            }
            if (rc && rc.length) {
              rc.forEach((rcItem) => {
                const { id: selectionId, batb, batl } = rcItem;
                const marketIndex = this.eventData[0]?.markets.findIndex(
                  (item) => item.marketId == marketId
                );
                this.eventData[0]?.markets[marketIndex]?.runners?.forEach(
                  (runnerItem) => {
                    if (runnerItem.selectionId == selectionId) {
                      batb &&
                        batb.length &&
                        batb.forEach((batbItem) => {
                          if (batbItem) {
                            const priceSize = {
                              price: batbItem[1],
                              size: batbItem[2],
                            };
                            if (
                              runnerItem.exchange &&
                              runnerItem.exchange.availableToBack
                            ) {
                              runnerItem.exchange.availableToBack[batbItem[0]] =
                                priceSize;
                            } else {
                              console.error(
                                "Error: runnerItem.exchange.availableToBack is undefined or null"
                              );
                            }
                          }
                        });
                      batl &&
                        batl.length &&
                        batl.forEach((batlItem) => {
                          if (batlItem) {
                            const priceSize = {
                              price: batlItem[1],
                              size: batlItem[2],
                            };
                            if (
                              runnerItem.exchange &&
                              runnerItem.exchange.availableToLay
                            ) {
                              runnerItem.exchange.availableToLay[batlItem[0]] =
                                priceSize;
                            } else {
                              console.error(
                                "Error: runnerItem.exchange.availableToLay is undefined or null"
                              );
                            }
                          }
                        });
                    }
                  }
                );
              });
              this.handleUnmatchedBets(this.eventData);
            }

            // this.eventSideBarData = sideBarData;

            // this.eventSportID = this.eventData[0]?.event?.eventTypeId;
            // if (
            //   this.userSelectBat &&
            //   Object.keys(this.userSelectBat).length > 0
            // ) {
            //   let userSelectValue = "";
            //   if (this.userSelectBat.stack == "Back") {
            //     userSelectValue =
            //       this.eventData[0]?.markets[this.userSelectBat.market]
            //         ?.runners[this.userSelectBat.runner]?.exchange
            //         ?.availableToBack?.[this.userSelectBat.position];
            //   } else if (this.userSelectBat.stack == "Lay") {
            //     userSelectValue =
            //       this.eventData[0]?.markets[this.userSelectBat.market]
            //         ?.runners[this.userSelectBat.runner]?.exchange
            //         .availableToLay?.[this.userSelectBat.position];
            //   }
            //   // this.selectedPrice = userSelectValue?.price;
            //   // $(this.selectedBatID).html(this.selectedPrice);
            // }
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    newFetchEventDataOld(mc) {
      //mc market Change
      try {
        if (mc && mc.length) {
          mc.forEach((mcItem) => {
            const { id: marketId, rc, marketDefinition } = mcItem;
            // const { id: marketId, rc } = mcItem;
            // const marketDefinition = {
            //   bspMarket: false,
            //   turnInPlayEnabled: true,
            //   persistenceEnabled: true,
            //   marketBaseRate: 5,
            //   eventId: "33127912",
            //   eventTypeId: "4",
            //   numberOfWinners: 0,
            //   bettingType: "LINE",
            //   marketType: "1_INNING_3_OVR_LINE",
            //   marketTime: "2024-03-27T14:00:00.000Z",
            //   suspendTime: "2024-03-27T14:00:00.000Z",
            //   bspReconciled: false,
            //   complete: true,
            //   inPlay: true,
            //   crossMatching: false,
            //   runnersVoidable: false,
            //   numberOfActiveRunners: 1,
            //   betDelay: 3,
            //   status: "SUSPENDED",
            //   runners: [
            //     {
            //       status: "ACTIVE",
            //       sortPriority: 1,
            //       id: 15316,
            //     },
            //   ],
            //   regulators: ["MR_INT"],
            //   countryCode: "GB",
            //   discountAllowed: true,
            //   timezone: "GMT",
            //   openDate: "2024-03-27T14:00:00.000Z",
            //   version: 5820903187,
            //   lineMaxUnit: 999.5,
            //   lineMinUnit: 0.5,
            //   lineInterval: 1,
            //   priceLadderDefinition: {
            //     type: "LINE_RANGE",
            //   },
            // };

            // this.tempI = this.tempI + 1;

            if (marketDefinition) {
              // console.log("marketDefinitioon: ", marketDefinition);
              const { marketType } =
                marketDefinition;
              // console.log("status: ", status);
              // console.log("marketType: ", marketType);
              // console.log("suspendTime: ", suspendTime);
              // console.log("inPlay: ", inPlay);
              // console.log("complete: ", complete);

              this.eventData[0]?.markets.forEach((marketItem) => {
                // console.log(
                //   "marketItem?.description?.marketType: ",
                //   marketItem?.description?.marketType
                // );
                // if (
                //   this.tempI == 8 &&
                //   marketItem?.description?.marketType === marketType
                // ) {
                if (marketItem?.description?.marketType === marketType) {
                  // console.log("marketTypeif: ", marketType);
                  marketItem.marketDefinition = marketDefinition;
                  // console.log(
                  //   "marketItem.marketDefinition: ",
                  //   marketItem.marketDefinition
                  // );
                }
              });
              // console.log(
              //   "this.eventData[0]?.markets: ",
              //   this.eventData[0]?.markets
              // );
              // will change the event data from here
              // console.log(
              //   "marketDefinitionfromNewFetchData: ",
              //   marketDefinition
              // );
            }
            if (rc && rc.length) {
              rc.forEach((rcItem) => {
                const { id: selectionId, atb, atl } = rcItem;
                const marketIndex = this.eventData[0]?.markets.findIndex(
                  (item) => item.marketId == marketId
                );
                this.eventData[0]?.markets[marketIndex]?.runners?.forEach(
                  (runnerItem) => {
                    if (runnerItem.selectionId == selectionId) {
                      atb &&
                        atb.length &&
                        atb.forEach((atbItem, atbIndex) => {
                          if (atbItem) {
                            const priceSize = {
                              price: atbItem[0],
                              size: atbItem[1],
                            };
                            if (
                              runnerItem.exchange &&
                              runnerItem.exchange.availableToBack
                            ) {
                              runnerItem.exchange.availableToBack[atbIndex] =
                                priceSize;
                            } else {
                              console.error(
                                "Error: runnerItem.exchange.availableToBack is undefined or null"
                              );
                            }
                          }
                        });
                      atl &&
                        atl.length &&
                        atl.forEach((atlItem, atlIndex) => {
                          if (atlItem) {
                            const priceSize = {
                              price: atlItem[0],
                              size: atlItem[1],
                            };
                            if (
                              runnerItem.exchange &&
                              runnerItem.exchange.availableToLay
                            ) {
                              runnerItem.exchange.availableToLay[atlIndex] =
                                priceSize;
                            } else {
                              console.error(
                                "Error: runnerItem.exchange.availableToLay is undefined or null"
                              );
                            }
                          }
                        });
                      // const atlToUpdate = runnerItem?.exchange?.availableToLay;
                      // if (atl && atlToUpdate) {
                      //   if (atl.length > 2) {
                      //     atl.forEach((atlItem, atlIndex) => {
                      //       const maxAtlCount = 2;
                      //       if (atlItem) {
                      //         const priceSize = {
                      //           price: atlItem[0],
                      //           size: atlItem[1],
                      //         };
                      //         if (atlToUpdate) {
                      //           // this minus is done to revert index 0->2, 1->1, 2->0
                      //           atlToUpdate[maxAtlCount - atlIndex] = priceSize;
                      //         } else {
                      //           console.error(
                      //             "Error: atlToUpdate is undefined or null"
                      //           );
                      //         }
                      //       }
                      //     });
                      //   } else if (atl.length == 2) {
                      //     //0, 1
                      //     atlToUpdate[2] = {
                      //       price: atl[1][0],
                      //       size: atl[1][1],
                      //     };
                      //     atlToUpdate[1] = {
                      //       price: atl[0][0],
                      //       size: atl[0][1],
                      //     };
                      //     // atlToUpdate[0] = undefined;
                      //   } else if (atl.length == 1) {
                      //     //0
                      //     atlToUpdate[2] = {
                      //       price: atl[0][0],
                      //       size: atl[0][1],
                      //     };
                      //     // atlToUpdate[1] = undefined;
                      //     // atlToUpdate[0] = undefined;
                      //   }
                      // }
                    }
                  }
                );
              });
              this.handleUnmatchedBets(this.eventData);
            }

            // this.eventSideBarData = sideBarData;

            // this.eventSportID = this.eventData[0]?.event?.eventTypeId;
            // if (
            //   this.userSelectBat &&
            //   Object.keys(this.userSelectBat).length > 0
            // ) {
            //   let userSelectValue = "";
            //   if (this.userSelectBat.stack == "Back") {
            //     userSelectValue =
            //       this.eventData[0]?.markets[this.userSelectBat.market]
            //         ?.runners[this.userSelectBat.runner]?.exchange
            //         ?.availableToBack?.[this.userSelectBat.position];
            //   } else if (this.userSelectBat.stack == "Lay") {
            //     userSelectValue =
            //       this.eventData[0]?.markets[this.userSelectBat.market]
            //         ?.runners[this.userSelectBat.runner]?.exchange
            //         .availableToLay?.[this.userSelectBat.position];
            //   }
            //   // this.selectedPrice = userSelectValue?.price;
            //   // $(this.selectedBatID).html(this.selectedPrice);
            // }
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    clearAllIntervals() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    clickSwitchBtn() {
      const mainContent = document.getElementById("main");
      //const switchBTN = document.getElementById("switch")
      if (mainContent.classList.contains("main--light")) {
        mainContent.classList.remove("main--light");
        mainContent.classList.add("main--dark");
      } else {
        mainContent.classList.remove("main--dark");
        mainContent.classList.add("main--light");
      }
    },
    handleKeyPress(event) {
      // this.refreshHeaderKey += 1;
      //  this.eventBetListRefreshKey += 1;

      //   this.refreshBetModel += 1
      if (event.key === "b" || event.key === "B") {
        const bhav =
          this.eventData[0]?.markets[0]?.runners[0]?.exchange
            ?.availableToBack?.[0].price;
        const runnerName =
          this.eventData[0]?.markets[0]?.runners[0]?.description?.runnerName;
        const marketName =
          this.eventData[0]?.markets[0]?.description?.marketName;
        const marketType =
          this.eventData[0]?.markets[0]?.description?.marketType;
        const marketId = this.eventData[0]?.markets[0]?.marketId;
        const marketStatus =
          this.eventData[0]?.markets[0]?.marketDefinition?.status;
        const runner = this.eventData[0]?.markets[0]?.runners[0];

        if (bhav && runnerName && marketName && marketType) {
          this.openBetModel(
            `match-0runner-0`,
            bhav,
            "Back",
            runnerName,
            marketName,
            {
              market: 0,
              runner: 0,
              position: 0,
              stack: "Back",
              btn_number: 6,
            },
            marketType,
            marketId,
            marketStatus,
            runner
          );
        }
      }
      if (event.key === "l" || event.key === "L") {
        const bhav =
          this.eventData[0]?.markets[0]?.runners[0]?.exchange
            ?.availableToLay?.[2].price;
        const runnerName =
          this.eventData[0]?.markets[0]?.runners[0]?.description?.runnerName;
        const marketName =
          this.eventData[0]?.markets[0]?.description?.marketName;
        const marketType =
          this.eventData[0]?.markets[0]?.description?.marketType;
        const marketId = this.eventData[0]?.markets[0]?.marketId;
        const marketStatus =
          this.eventData[0]?.markets[0]?.marketDefinition?.status;
          const runner =
          this.eventData[0]?.markets[0]?.runners[0];

        if (bhav && runnerName && marketName) {
          this.openBetModel(
            `match-0runner-0`,
            bhav,
            "Lay",
            runnerName,
            marketName,
            {
              market: 0,
              runner: 0,
              position: 2,
              stack: "Lay",
              btn_number: 6,
            },
            marketType,
            marketId,
            marketStatus,
            runner
          );
        }
      }
    },
    logOut() {
      DataService.logout()
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.isLoading = false;
        });
    },
    showMarketRule(rules) {
      $(".game-rule-modal").modal("show");
      this.eventRule = rules;
      // DataService.getEventRule("cricket")
      //   .then((response) => {
      //     if (response.status == 200) {
      //       if (response.data.status) {
      //         const data = response.data.data;

      //         alert(JSON)
      //         this.ruleRefreshKey += 1;

      //         this.eventRule = data?.rule;
      //         $(".game-rule-modal").modal("show");
      //       }
      //     }
      //   })
      //   .catch((e) => {
      //     console.error("error", e);
      //     this.$toast.error("Please Try Again.");
      //     this.isLoading = false;
      //   });
    },
    getEventRule() {
      this.eventRule = "";
      DataService.getEventRule("cricket")
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              const data = response.data.data;
              this.ruleRefreshKey += 1;

              this.eventRule = data?.rule;
            }
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.$toast.error("Please Try Again.");
          this.isLoading = false;
        });
    },
    openBookEventModel(marketId, market, runner) {
      const data = {
        event_id: this.event_id,
        team: runner,
        stack_type: market,
      };
      DataService.getEventBookDetail(data)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.eventBookDetail = {
              market,
              runner,
            };
            if (data) {
              this.eventBookDetail.data = data;
            }
            this.bookData = DataService.getBookData(marketId)
            $("#bookEventModel").modal("show");
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.$toast.error("Please Try Again.");
          this.isLoading = false;
        });
    },
    getEventLiabiliityData() {
      DataService.getEventLiabiliity(this.event_id)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            if (data) {
              this.eventLiabiliity = data;
            }

            this.refreshHeaderKey += 1;
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.isLoading = false;
        });
    },
    getEventoddsLiability() {
      
      const data = {
          event_id: this.event_id,
          market_id: this.marketId,
          selected_option: this.selectedOption,
          team: this.selectedTeam,
          bhav: this.userBetBhav,
          amount: this.userAmount,
          selection_id : this.selection_id,
          stack_type: this.selectedstack_type,
          match_name: this.eventData[0].event.name,
          match_date: [
            this.eventData[0].event.openDate,
            this.eventData[0].event.timezone,
          ],
          sport_id: this.eventSportID,
          event_team: this.team,
          bet_index: this.bet_index,
      };
      
      DataService.getEventoddsLiability(data)
        .then((response) => {
          this.team1_odds_amount = 0;
          this.team2_odds_amount = 0;
          this.the_draw_amount  = 0;
          if (response.status == 200) {
            const data = response.data;
            // console.log("response eventOddsLiability",response.data);
            if (data) {
              let keys_data = Object.keys( data );
              if( keys_data[0] == this.team.team1.runner_name){
                this.team1_odds_amount = data[keys_data[0]];
                this.team2_odds_amount = data[keys_data[1]];
                this.the_draw_amount   = data[keys_data[1]];
              } else if( keys_data[0] == 'The Draw' ) {
                this.team1_odds_amount = data[keys_data[1]];
                this.team2_odds_amount = data[keys_data[1]];
                this.the_draw_amount   = data[keys_data[0]];
              } else {
                this.team1_odds_amount = data[keys_data[1]];
                this.team2_odds_amount = data[keys_data[0]];
                this.the_draw_amount   = data[keys_data[1]];
              }
            } 
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.isLoading = false;
        });
    },
    getUserStackButtonData() {
      DataService.getUserStackButton()
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.refreshBetModel += 1;
            if (data) {
              this.button_1 = data.button_1;
              this.button_2 = data.button_2;
              this.button_3 = data.button_3;
              this.button_4 = data.button_4;
              this.button_5 = data.button_5;
              this.defaultAmount = data.default_button;
            }
          }
        })
        .catch((e) => {
          console.error("error", e);
          this.isLoading = false;
        });
    },
    betAmountUpdate(amount, btn = "+") {
       
      // if(!this.userButtonLastPress || this.userButtonLastPress != amount){
      //   this.userAmount = amount
      //   this.userButtonLastPress = amount
      // }else{
      //   const currentAmount = this.userAmount;
      //   const number1 = parseFloat(currentAmount);
      //   const number2 = parseFloat(amount);
      //   const sum = number1 + number2;
      //   const resultString = sum.toString();
      //   this.userAmount = resultString
      // }
      const currentAmount = this.userAmount ? this.userAmount : 0;
      const number1 = parseFloat(currentAmount);
      const number2 = parseFloat(amount);
      const sum = btn == "+" ? number1 + number2 : number1 - amount;
       
      if(sum < 0){
        return;
      }
      const resultString = sum.toString();

      this.userAmount = resultString;
      let plamount = this.userBetBhav * this.userAmount;

      if (this.userSelectBat?.market) {
        plamount = parseFloat(this.userAmount);
      }
      this.userProfitLoss = plamount.toFixed(2);
      $(`.enter-bet-ammount`).focus();

      this.getEventoddsLiability();
    },
    onAmountInput() {
      // Remove non-numeric characters from the input
      this.userAmount = this.userAmount.replace(/[^0-9]/g, "");
      let plamount = this.userBetBhav * this.userAmount;
      if (this.userSelectBat?.market) {
        let amountPL = 0;
        if (this.userAmount) {
          amountPL = this.userAmount;
        }
        plamount = parseFloat(amountPL);
      }
      
      this.userProfitLoss = plamount.toFixed(2);
      this.getEventoddsLiability();
    },
    getBackgroundColor(selectedOption) {
      let color = "";
      if (selectedOption === "Back") {
        color = "#bcdef3";
      } else if (selectedOption === "Lay") {
        color = "#f5dce2";
      }
      return color;
    },
    getEventBatList() {
      const data = {
        event_id: this.event_id,
      };
      if (this.userSelectedMarket != null) {
        const findedMarket = this.eventSideBarData.market.find(
          (item) => item.index === this.userSelectedMarket
        );
        data.market_name = findedMarket?.name;
      }

      DataService.getEventBetsList(data)
        .then((response) => {
         
          const data = response.data.data;
          const total = response.data.total;
          const average = response.data.average;
          if (data.matched) {
            this.userEventBats = data.matched;
            this.has_line_bet = this.userEventBats.filter((item) => item.stack_type != 'Match Odds').length > 0 ? true : false; //has line item
          }
          if (total.matched) {
            this.eventTotalAmount = total.matched;
          } else {
            this.eventTotalAmount = "";
          }
          if (data.unmatched) {
            this.userUnmatchedEventBats = data.unmatched;

            this.socket.emit(`unmatched-bets`, data.unmatched);
          }
          if (total.unmatched) {
            this.eventUnmatchedTotalAmount = total.unmatched;
          } else {
            this.eventUnmatchedTotalAmount = "";
          }
          if (average) {
            if (average.matched) {
              this.macthedEventAverage = average.matched;
            } else {
              this.macthedEventAverage = "";
            }
            if (average.unmatched) {
              this.unmacthedEventAverage = average.unmatched;
            } else {
              this.unmacthedEventAverage = "";
            }
          } else {
            this.macthedEventAverage = "";
            this.unmacthedEventAverage = "";
          }

          this.eventBetListRefreshKey += 1;
        })
        .catch((e) => {
          console.error("error", e);
          this.isLoading = false;
        });
    },
    openBetModel(
      id,
      price,
      option,
      team,
      stack_type,
      selectValue,
      marketNames,
      marketId,
      status,
      runner
    ) {
      
      if (this.auth.role !== "user") {
        return;
      }

      if (selectValue.btn_number == 3 || selectValue.btn_number == 4) {
        this.$toast.error("Bets not allowed on these odds!");
        return;
      }
      if (status === "CLOSED") {
        this.$toast.error("Bets not allowed on closed markets!");
        return;
      }
      if (status === "SUSPENDED") {
        this.$toast.error("Bets not allowed on suspended markets!");
        return;
      }

      // if(this.isDrawPresent) return;

      this.userAmount = this.defaultAmount;
      this.team1_odds_amount =  0;
      this.team2_odds_amount =  0;
      this.the_draw_amount  = 0;
      if (price) {
        if (option == "Back") {
          this.modelBackGround =
            "linear-gradient(90deg, #457fca -66.05%, #84c2f1 93%)";
        } else if (option == "Lay") {
          this.modelBackGround =
            "linear-gradient(90deg, #f4c4f3 -66.05%, #f59dc2 93%)";
        }
        this.userBetBhav = price;

        if ($(".tab-2-content-ammount-set-sec:visible").length > 0) {
          // If none are visible, proceed with the fade-in for the new element
          $(".tab-2-content-ammount-set-sec").fadeOut(10);
        }

        $(`#${id}`).fadeIn(250);

        $(`#${id}-price`).html(price);
        this.selectedBatID = `#${id}-price`;
        this.selectedPrice = price;
        this.selectedOption = option;
        this.selectedTeam = team;
        this.selectedstack_type = stack_type;
        this.userButtonLastPress = "";
        this.userSelectBat = selectValue;
        this.marketNames = marketNames;
        this.marketId = marketId;
        this.selection_id = runner.selectionId;
        this.bet_index = this.userSelectBat.position;
        let plamount = this.userBetBhav * this.userAmount;
        if (this.userSelectBat?.market) {
          plamount = parseFloat(this.userAmount);
        }
        this.userProfitLoss = plamount.toFixed(2);
        //   this.userChangedBhav = true;
        // this.userBetBhav = this.userBetBhav.replace(/[^0-9.]/g, "");
        // let plamount = this.userBetBhav * this.userAmount;
        // if (this.userSelectBat?.market && this.userSelectBat?.market >= 4) {
        //   plamount = parseFloat(this.userAmount);
        // }

        // finalAmount = parseFloat(this.userBetBhav) + increment;
        // const resultString = finalAmount.toFixed(2);
        // this.userChangedBhav = true;
        // this.userBetBhav = resultString;
        // // let plamount = (this.userBetBhav - 1) * this.userAmount;
        // let plamount = this.userBetBhav * this.userAmount;
        // if (this.userSelectBat?.market && this.userSelectBat?.market >= 4) {
        //   plamount = parseFloat(this.userAmount);
        // }

        // finalAmount = parseFloat(this.userBetBhav) - decrement;
        // const resultString = finalAmount.toFixed(2);
        // this.userChangedBhav = true;
        // if (resultString > 0) {
        //   this.userBetBhav = resultString;
        // }
        // // let plamount = (this.userBetBhav - 1) * this.userAmount;
        // let plamount = this.userBetBhav * this.userAmount;
        // if (this.userSelectBat?.market && this.userSelectBat?.market >= 4) {
        //   plamount = parseFloat(this.userAmount);
        // }

        // this.userAmount = resultString;
        // let plamount = this.userBetBhav * this.userAmount;

        // if (this.userSelectBat?.market && this.userSelectBat?.market >= 4) {
        //   plamount = parseFloat(this.userAmount);
        // }

        setTimeout(() => {
          $(`.enter-bet-ammount`).focus();
        }, 150);
        const taskScrollClass = document.getElementById(id);
        setTimeout(() => {
          taskScrollClass.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 260);
      } else {
        $(".tab-2-content-ammount-set-sec").fadeOut(10);

        // const taskScrollClass = document.getElementById("task-comment-list")
        // setTimeout(() => {
        // taskScrollClass.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        // },800)
      }
    },
    updateUserBalance() {
      DataService.getUserBalanace()
        .then((response) => {
          const findUser = SessionService.getUser();
          const balance = response.data.data.balance;
          findUser.balance = balance;
          this.auth = findUser;
          SessionService.setUser(findUser);
        })
        .catch((e) => {
          console.error("error", e);
        });
    },
    clearBet(event, closeModal = false, indexM = null, indexR = null) {
      if(closeModal) {
        this.selectedPrice = "";
        this.selectedOption = "";
        this.selectedTeam = "";
        this.selectedstack_type = "";
        this.marketNames = "";
        this.marketId = "";
        this.userSelectBat = {};
        $(".tab-2-content-ammount-set-sec").fadeOut();
      } else {
        this.userProfitLoss = "0.00";
        if (indexM !== null && indexR !== null) {
          this.$refs[`amoutBetInput-${indexM}-${indexR}`][0].focus();
        }
      }
      this.userAmount = "";
      this.team1_odds_amount = 0;
      this.team2_odds_amount = 0;
      this.the_draw_amount  = 0;
    },
    placeBet() {
      // if (!this.is_event_api_okay) return this.$toast.error("Please try Again");
      if (this.userBetBhav < 1) {
        this.$toast.error("Please Try Again!");
        return;
      }
      if (this.loading) return;

      const data = {
        event_id: this.event_id,
        market_id: this.marketId,
        selected_option: this.selectedOption,
        team: this.selectedTeam,
        bhav: this.selectedPrice,
        amount: this.userAmount,
        selection_id : this.selection_id,
        stack_type: this.selectedstack_type,
        match_name: this.eventData[0].event.name,
        match_date: [
          this.eventData[0].event.openDate,
          this.eventData[0].event.timezone,
        ],
        sport_id: this.eventSportID,
        event_team: this.team,
        bet_index: this.bet_index,
        bet_event_type: this.event_type_data,
      };

      if (this.userSelectBat?.market) {
        data.fancy = 1;
      }
      if (this.userChangedBhav) {
        if (
          this.userBetBhav > this.selectedPrice &&
          this.selectedOption !== "Lay"
        ) {
          data.unmatched = true;
          data.bhav = this.userBetBhav;
        } else {
          if (
            this.selectedOption == "Lay" &&
            this.userBetBhav < this.selectedPrice
          ) {
            data.unmatched = true;
            data.bhav = this.userBetBhav;
          }
        }
      }
      const userSelectedMarket =
        this.eventData[0].markets[this.userSelectBat.market]?.description
          ?.marketType;
      if (userSelectedMarket) {
        data.market = userSelectedMarket;
      }

      this.loading = true;

      DataService.userPlaceBet(data)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              this.refreshHeaderKey += 1;
              this.updateUserBalance();
              this.selectedPrice = 0;
              this.selectedOption = "";
              this.selectedTeam = "";
              this.selectedstack_type = "";
              this.marketNames = "";
              this.marketId = "";
              this.bet_index = "";
              // this.userAmount = 0
              this.userAmount = this.defaultAmount;
              this.userSelectBat = {};
              $(".tab-2-content-ammount-set-sec").fadeOut();

              this.getEventBatList();
              this.$toast.success(response.data.message);
              this.getEventLiabiliityData();
            }
          }
        })
        .catch((e) => {
          console.error("data", data);
          console.error("error", e);
          this.isLoading = false;
          if (e.response.data.message) {
            this.$toast.error(e.response.data.message);
          }
          if (e.response.data.error.amount) {
            this.$toast.error(e.response.data.error.amount[0]);
          }
          if (e.response.data.error.bhav) {
            this.$toast.error("Please Again Select Bet");
          }
          if (e.response.status == 400) {
            const data = e.response.data;
            this.error = [];
            if (data.errors) {
              data.errors.forEach((error) => {
                this.error[error.param] = error.msg;
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  // sockets: {
  //   dataUpdate : function(data){
  //   },
  //   evevntKnow: function(){
  //     this.$socket.emit("userEvent", this.event_id);
  //   },
  //   eventData: function (events) {
  //     if (this.event_id == events[0]?.event?.eventId) {
  //       this.eventRefreshKey += 1
  //       this.eventData = [];
  //       this.eventData = events;
  //       this.eventSportID = events[0]?.event?.eventTypeId
  //       if (this.userSelectBat && Object.keys(this.userSelectBat).length > 0) {
  //         let userSelectValue = "";
  //         if (this.userSelectBat.stack == "Back") {
  //           userSelectValue =
  //             this.eventData[0]?.markets[this.userSelectBat.market]?.runners[
  //               this.userSelectBat.runner
  //             ]?.exchange?.availableToBack?.[this.userSelectBat.position];
  //         } else if (this.userSelectBat.stack == "Lay") {
  //           userSelectValue =
  //             this.eventData[0]?.markets[this.userSelectBat.market]?.runners[
  //               this.userSelectBat.runner
  //             ]?.exchange.availableToLay?.[this.userSelectBat.position];
  //         }
  //         this.selectedPrice = userSelectValue?.price;
  //         $(this.selectedBatID).html(this.selectedPrice);
  //       }
  //     }
  //     // this.events = events
  //   },
  // },
};
</script>
