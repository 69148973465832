<template>
  <HeaderComponent :key="this.refreshHeaderKey" />

  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Login</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter your password"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Login</button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-0">
    <div class="main-con d-flex">
      <SideBar />
      <SideBet @changeKeyHeader="updateHeader()"/>
      <div class="tab-content w-100 overflow-auto" id="pills-tabContent">
        <div
          class="tab-pane fade show active w-100"
          id="pills-exchange"
          role="tabpanel"
          aria-labelledby="pills-exchange-tab"
          tabindex="0"
        >
          <main class="main-all-sec-con d-flex flex-column justify-content-start">
            <div class="tab-2-content-sec-1">
              <div class="container p-0">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-in-play"
                    role="tabpanel"
                    aria-labelledby="pills-in-play-tab"
                    tabindex="0"
                  >
                    <div class="tab-2-content-sec-1-inner-con">
                      <div
                        class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                      >
                        <h3 class="tab-2-content-sec-1-head">Exposure</h3>
                      </div>
                      <!-- <div class="row m-4">
                        <div class="form-group col-md-3">
                          <label for="startDate">Start Date:</label>
                          <input
                            type="date"
                            id="startDate"
                            class="form-control"
                            v-model="this.filterStartDate"
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label for="endDate">End Date:</label>
                          <input
                            type="date"
                            id="endDate"
                            class="form-control"
                            v-model="this.filterEndDate"
                          />
                        </div>
                        <div class="form-group col-md-3 align-self-end">
                          <button
                            id="filterByDate"
                            class="btn btn-primary btn-filter"
                            @click="this.filterBetTable()"
                          >
                            Apply Date Filter
                          </button>
                        </div>
                      </div> -->
                      <div class="bets-table" >
                        <!-- <table
                          ref="dataTable"
                          class="display"
                          style="width: 100%"
                        ></table> -->
                        <table class="table" style="background: none !important;">
                          <thead>
                            <tr>
                              <th scope="col">Exposure</th>
                              <th scope="col">Event</th>
                              <th scope="col">Market</th>
                              <th scope="col">Game</th>
                              <th scope="col">Event ID</th>
                            </tr>
                          </thead>
                          <tbody v-if="currentLiabilityList && currentLiabilityList.length > 0">
                            <tr v-for=" (liability, index) in currentLiabilityList" v-bind:index="index" v-bind:key="index">
                              <td class="text-right">{{liability.amount}}</td>
                              <td>{{liability.name}}</td>
                              <td>{{liability.market}}</td>
                              <td>{{liability.event_type.toUpperCase()}}</td>
                              <td class="text-right" ><a  :href="'event/'+liability.event_type+'/'+liability.event_id">{{liability.event_id}}</a></td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="4" class="text-center">No Records</td>
                            </tr>
                          </tbody>  
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div
          class="tab-pane fade"
          id="pills-ezugi"
          role="tabpanel"
          aria-labelledby="pills-ezugi-tab"
          tabindex="0"
        >
          <h1 class="text-center m-5 p-0" style="font-size: 30px">Hello</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UserHome from './user/UserHome.vue'
// import AdminHome from './admin/AdminHome.vue'
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
import $ from "jquery"; // import jQuery
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-buttons-dt/css/buttons.dataTables.css" //export button styling
import 'datatables.net-dt/css/dataTables.dataTables.css'; //
import HeaderComponent from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import SideBet from '@/components/SideBet.vue'
export default {
  name: "homeAll",
  components: {
    HeaderComponent,
    SideBar,
    SideBet
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      events: [],
      filterStartDate: "",
      filterEndDate: "",
      dataTable: "",
      transitions: {},
      liabilityDataTable: "",
      refreshHeaderKey : 0,
      currentLiabilityList : []
    };
  },
  // components: {
  // 	UserHome,AdminHome
  // },
  created: function () {
    this.auth = SessionService.getUser();

    // $(document).ready(() => {
    //   this.getUserTransaction();
    // });
    this.getCurrentLiability()
  },
  methods: {
    updateHeader(){
      this.refreshHeaderKey += 1;
    },
    logOut() {
      DataService.logout()
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
        });
    },
    getCurrentLiability(){
      DataService.userCurrentLiability().then( (response) => {
        console.log('response' , response)
        if(response.data.status){
          this.currentLiabilityList = response.data.data 
        }
      } )
      .catch((e) => {
        console.log("error", e);
        this.isLoading = false;
      });
    },
    
    getUserTransaction() {
      try {
        this.liabilityDataTable = $(this.$refs.dataTable).DataTable({
          processing: true,
          serverSide: true,
          destroy: true,
          pageLength: 10,
          searching: false,
          autoWidth: false,
          aaSorting: [],
          columnDefs: [
            {
              orderable: false,
              targets: [1, 2, 4],
            },
            {
              targets: [0,1,4,5,6],
              className: "text-right",
            },
          ],
          // data: response.data.data,
          ajax: {
            method: "POST",
            url: DataService.getUserLiabilityLink(),
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", DataService.getAuthToken());
              return true;
            },
            data: (d) => {
              // d.outcome      = this.filterOutcome;
              // d.startDate    = this.filterStartDate;
              // d.endDate     = this.filterEndDate;
              // d.status     = this.filterStatus;
              return d;
            },
          },
          columns: [
            { title: "No.", data: "index" },
            { title: "Event ID", data: "event_id" }, 
            { title: "Event name", data: "event_name" }, 
            { title: "Narration", data: "narration" },
            { title: "Price", data: "price" },
            { title: "Amount", data: "bet_amount" },
            { title: "Exposure", data: "liability" },
            { title: "Side", data: "side" },
            // { title: "Balance", data: "balance" },
            { title: "Date", data: "created_at" },
          ],
          initComplete: () => {
            if (this.liabilityDataTable.ajax.json()) {
              const data = this.liabilityDataTable.ajax.json();
              if (data.startDate) {
                this.filterStartDate = data.startDate;
              }
              if (data.endDate) {
                this.filterEndDate = data.endDate;
              }
            }
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    filterBetTable() {
      this.liabilityDataTable.settings()[0].ajax.data = (d) => {
        d.startDate = this.filterStartDate;
        d.endDate = this.filterEndDate;
      };
      this.liabilityDataTable.ajax.reload();
    },
  },
};
</script>
